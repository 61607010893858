/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnassignPersonFromTaskInput = {|
  taskId?: ?string,
  personId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type unassignPersonFromTaskMutationVariables = {|
  input: UnassignPersonFromTaskInput
|};
export type unassignPersonFromTaskMutationResponse = {|
  +unassignPersonFromTask: ?{|
    +task: ?{|
      +id: string,
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |}
  |}
|};
export type unassignPersonFromTaskMutation = {|
  variables: unassignPersonFromTaskMutationVariables,
  response: unassignPersonFromTaskMutationResponse,
|};
*/


/*
mutation unassignPersonFromTaskMutation(
  $input: UnassignPersonFromTaskInput!
) {
  unassignPersonFromTask(input: $input) {
    task {
      id
      assignedPersons {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnassignPersonFromTaskInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnassignPersonFromTaskPayload",
    "kind": "LinkedField",
    "name": "unassignPersonFromTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "assignedPersons",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unassignPersonFromTaskMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unassignPersonFromTaskMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "unassignPersonFromTaskMutation",
    "operationKind": "mutation",
    "text": "mutation unassignPersonFromTaskMutation(\n  $input: UnassignPersonFromTaskInput!\n) {\n  unassignPersonFromTask(input: $input) {\n    task {\n      id\n      assignedPersons {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '016d05f4f3dc9267e5827eef5c4ae411';

module.exports = node;
