import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import Moment from 'moment';
import {BottomSectionSubHeader, SubHeaderElem} from '../../timesheets_styled';
import {BottomSectionWrapperOld} from '../BottomSectionWrapper_styled';
import {APPROVAL_STATUS, BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../../../constants';
import {TIME_PERIOD} from '../../timesheets_change_date_view';
import {getHolidaysOfTheDay} from '../../timesheets_person_data';
import HeaderBar from '../../../../shared/components/headers/header-bar/header_bar';
import {getInitialOptions, handleChangedOptions} from '../../timesheets_the_eye_options';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import InlineLoader from '../../../../shared/components/inline-loader/inline_loader';
import {ButtonGroupHeader} from '../button_group_header';
import {constructSearchQuery} from './timesheets_data_fetch_util';
import {MODAL_TYPE, showModal} from '../../../../shared/components/modals/generic_modal_conductor';
import Util from '../../../../shared/util/util';
import HeaderLabel from '../../../../../components/new-ui/HeaderLabel';
import {CUSTOM_FIELD_PREFIX} from '../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {hasFeatureFlag} from '../../../../shared/util/FeatureUtil';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import TimesheetTableWrapper, {
	TimesheetTableWrapperQuery,
} from '../timesheets-time-registration-table/deprecated/TimesheetTableWrapper';
import {differenceInMinutes} from 'date-fns';
import {triggerUserPilotOnce} from '../../../../shared/util/UserPilotUtil';
import {USER_PILOT_FLOW_ON_REGISTERING_OFTEN} from '../../../../shared/util/UserPilotConstants';

function triggerUserPilotFlowIfConditionsAreMet(timeRegistrations, showingViewer) {
	const timeRegCreatedTimes = timeRegistrations?.map(edge => edge?.node?.updatedAt);
	const recentTimeRegCreatedTimes = timeRegCreatedTimes?.filter(t => differenceInMinutes(new Date(), new Date(t)) < 30);
	const registeredTimeOnSelfFiveTimesRecently = recentTimeRegCreatedTimes.length >= 5 && showingViewer;

	if (registeredTimeOnSelfFiveTimesRecently) {
		triggerUserPilotOnce(USER_PILOT_FLOW_ON_REGISTERING_OFTEN);
	}
}

const timesheetsBottomSection = ({
	timeRegistrations,
	groupLocked,
	currentViewingDate,
	lastWorkingDayInWeek,
	timePeriod,
	selectedPerson,
	showingViewer,
	selectedSwitch,
	setSelectedSwitch,
	selectDayFromCalendar,
	lockedDate,
	companyLockedDate,
	usingTimeApproval,
	usingInternalTimeApproval,
	usingTimeOffApproval,
	customFieldDefinitions,
	overBudgetProgramsByCompanyProjectId,
	timeRegistrationTargetSuggestionsData,
}) => {
	const intl = useIntl();
	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions(customFieldDefinitions));
	const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;

	useEffect(() => {
		theEyeOptions.forEach(option => {
			if (option.name.startsWith(CUSTOM_FIELD_PREFIX)) {
				option.hide = isWeekView;
			}
		});
		setTheEyeOptions(theEyeOptions);
	}, [timePeriod]);

	const selectedSwitchLocalStorageKey = hasFeatureFlag('timereg_show_new_task_suggestions')
		? 'timesheets-selected-switch-v2'
		: 'timesheets-selected-switch';

	const [localSelectedSwitch] = useState(localStorage.getItem(selectedSwitchLocalStorageKey) || 'suggested');
	const fetchSuggestedTasks = localSelectedSwitch === 'suggested';
	const fetchSwitchTasks =
		localSelectedSwitch !== null && localSelectedSwitch !== 'none' && localSelectedSwitch !== 'suggested';

	const taskTypeSearchQuery = useMemo(() => {
		return constructSearchQuery(localSelectedSwitch, currentViewingDate, isWeekView);
	}, [localSelectedSwitch, currentViewingDate, isWeekView]);

	const isFullyLocked = useMemo(() => {
		if (lockedDate) {
			return isWeekView
				? lockedDate.isSameOrAfter(currentViewingDate.clone().endOf('week'), 'd')
				: lockedDate.isSameOrAfter(currentViewingDate, 'd');
		}
		return false;
	}, [isWeekView, lockedDate, currentViewingDate]);

	const isCompanyLocked = useMemo(() => {
		if (companyLockedDate) {
			return isWeekView
				? companyLockedDate.isSameOrAfter(currentViewingDate.clone().endOf('week'), 'd')
				: companyLockedDate.isSameOrAfter(currentViewingDate, 'd');
		}
		return false;
	}, [isWeekView, companyLockedDate, currentViewingDate]);

	const isWorkdaysLocked = useMemo(() => {
		if (lockedDate && lastWorkingDayInWeek) {
			return lockedDate.isSameOrAfter(lastWorkingDayInWeek, 'd');
		}
		return false;
	}, [isWeekView, lockedDate, lastWorkingDayInWeek]);

	triggerUserPilotFlowIfConditionsAreMet(timeRegistrations, showingViewer);

	const columns = [...theEyeOptions];
	columns.splice(0, 0, {name: 'switch-task-indicator', checked: true});
	columns.splice(3, 0, {name: 'task-name', checked: true});
	columns.push(
		{name: 'hour-inputs', checked: true},
		{name: 'notes', checked: true},
		{name: 'harvest', checked: true},
		{name: 'unit4', checked: true},
		{name: 'star', checked: true},
		{name: 'context-menu', checked: true}
	);

	const enabledColumns = useMemo(() => columns, [theEyeOptions, isWeekView]);
	const [searchValue, setSearchValue] = useState('');

	const week = useMemo(
		() =>
			Array(7)
				.fill(0)
				.map((n, i) => currentViewingDate.clone().startOf('w').add(i, 'day')),
		[currentViewingDate]
	);

	const holidays = isWeekView
		? week.map(day => getHolidaysOfTheDay(selectedPerson, day))
		: getHolidaysOfTheDay(selectedPerson, currentViewingDate);
	const memoizedHolidays = useMemo(() => holidays, [isWeekView, selectedPerson, currentViewingDate]);

	const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
		// Preserve initial order
		const optionOrder = theEyeOptions.map(opt => opt.name);
		newOptions.sort((a, b) => (optionOrder.indexOf(a.name) > optionOrder.indexOf(b.name) ? 1 : -1));

		setTheEyeOptions(handleChangedOptions(newOptions));
	};

	const handleSearchValue = newValue => {
		setSearchValue(newValue);
	};

	const submitTimeOff = () => {
		trackEvent('Submit Time Off Bottom', 'Clicked', {
			timeRegPerson: groupLocked ? selectedPerson.email : 'myself',
		});
		showModal({
			type: MODAL_TYPE.SUBMIT_TIME_OFF_APPROVAL,
			selectedPersonName: groupLocked ? selectedPerson.firstName : undefined,
			selectedPersonId: selectedPerson.id,
		});
	};

	const submitTimesheets = () => {
		trackEvent('Submit Timesheets Bottom', 'Clicked', {
			timeRegPerson: groupLocked ? selectedPerson.email : 'myself',
		});
		const today = Moment();
		const submitDate = !isWeekView
			? currentViewingDate
			: today.isSame(currentViewingDate, 'week')
			? today
			: lastWorkingDayInWeek;
		showModal({
			type: MODAL_TYPE.SUBMIT_TIME_APPROVAL,
			selectedPersonName: groupLocked ? selectedPerson.firstName : undefined,
			selectedPersonId: selectedPerson.id,
			currentViewingDate: submitDate,
			lockedDate,
			usingTimeOffApproval,
		});
	};

	const unsubmitTimesheets = () => {
		trackEvent('Unsubmit Timesheets Bottom', 'Clicked', {
			timeRegPerson: groupLocked ? selectedPerson.email : 'myself',
		});
		const unsubmitDate = !isWeekView ? currentViewingDate : currentViewingDate.clone().startOf('week');
		showModal({
			type: MODAL_TYPE.UNSUBMIT_TIME_APPROVAL,
			selectedPersonName: groupLocked ? selectedPerson.firstName : undefined,
			selectedPersonId: selectedPerson.id,
			currentViewingDate: unsubmitDate,
			companyLockedDate,
			lockedDate,
		});
	};

	const getTimesheetsApprovalStatus = timeRegs => {
		const projectTimeRegs = timeRegs.filter(tReg => !tReg.node.idleTime);
		let timeRegStatus = projectTimeRegs.length > 0 ? APPROVAL_STATUS.APPROVED : APPROVAL_STATUS.NOT_SUBMITTED;
		for (const tReg of projectTimeRegs) {
			if (!tReg.node || (tReg.node.approvalStatus === APPROVAL_STATUS.NOT_SUBMITTED && !tReg.node.idleTime)) {
				return APPROVAL_STATUS.NOT_SUBMITTED;
			} else if (tReg.node.approvalStatus === APPROVAL_STATUS.SUBMITTED) {
				timeRegStatus = APPROVAL_STATUS.SUBMITTED;
			}
		}

		return timeRegStatus;
	};

	const weekStart = useMemo(() => currentViewingDate.clone().startOf('week'), [currentViewingDate]);
	const weekEnd = useMemo(() => currentViewingDate.clone().endOf('week'), [currentViewingDate]);

	const timeRegsForPeriod = timeRegistrations.filter(tReg => {
		const tDate = Util.CreateNonUtcMomentDate(tReg.node.year, tReg.node.month, tReg.node.day);
		if (isWeekView) {
			return tDate.isSameOrAfter(weekStart, 'days') && tDate.isSameOrBefore(weekEnd, 'days');
		} else {
			return currentViewingDate.isSame(tDate, 'date');
		}
	});

	const periodTimeApprovalStatus = usingTimeApproval ? getTimesheetsApprovalStatus(timeRegsForPeriod) : null;

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];
		const centerContent = [];

		leftContent.push(
			<SubHeaderElem>
				{isWeekView ? (
					<div className={'week-info'}>
						{currentViewingDate.clone().startOf('week').format('DD') +
							' - ' +
							currentViewingDate.clone().endOf('week').format('DD MMMM')}
						&nbsp;
						<div className={'week-number'}>
							{'(' +
								intl.formatMessage({id: 'overview_time.week'}) +
								' ' +
								currentViewingDate.clone().endOf('week').format('ww') +
								')'}
						</div>
					</div>
				) : (
					<div className={'day-info'}>
						{currentViewingDate.format('dddd DD MMMM')}&nbsp;
						<div className={'holiday-names'}>
							{holidays && holidays.length > 0 ? '(' + holidays.map(h => h.node.name).join(', ') + ')' : ''}
						</div>
					</div>
				)}
				{periodTimeApprovalStatus && isWorkdaysLocked && periodTimeApprovalStatus !== APPROVAL_STATUS.NOT_SUBMITTED ? (
					periodTimeApprovalStatus === APPROVAL_STATUS.APPROVED ? (
						<HeaderLabel
							text={intl.formatMessage({id: 'common.approved'})}
							color={HeaderLabel.INDICATOR_COLOR.GREEN}
						/>
					) : (
						<HeaderLabel
							text={intl.formatMessage({id: 'time_approval.submitted'})}
							color={HeaderLabel.INDICATOR_COLOR.BLUE}
						/>
					)
				) : null}
			</SubHeaderElem>
		);

		const search = {
			type: ELEMENT_TYPE.SEARCH,
			value: searchValue,
			onChange: handleSearchValue,
			cy: 'timesheets-table-search',
		};
		rightContent.push(search);

		if (usingTimeApproval && isWorkdaysLocked) {
			const unsubmitButton = {
				type: ELEMENT_TYPE.BUTTON,
				text: groupLocked
					? intl.formatMessage({id: 'time_approval.unsubmit_names_timesheets'}, {name: selectedPerson.firstName})
					: intl.formatMessage({id: 'time_approval.unsubmit_timesheets'}),
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.VERYLIGHTGREY,
				callback: unsubmitTimesheets,
				disabled: isCompanyLocked,
				dataCy: 'unsubmit-timesheets-button',
				userpilot: 'submit-timesheets-button',
			};
			rightContent.push(unsubmitButton);
		} else if (usingTimeApproval) {
			const messageIdSuffix = (usingTimeOffApproval ? '_and_time_off' : '') + '_info';
			const submitTimesheetButton = {
				type: ELEMENT_TYPE.BUTTON,
				text: groupLocked
					? intl.formatMessage({id: 'time_approval.submit_names_timesheets'}, {name: selectedPerson.firstName})
					: intl.formatMessage({id: 'time_approval.submit_timesheets'}),
				tooltipProps: hasFeatureFlag('pto_timesheet_allocation_linking')
					? {
							tooltipDuration: 30000,
							canBeMiddle: true,
							tooltipPosition: 'bottom',
							infoText: groupLocked
								? intl.formatMessage(
										{id: 'time_approval.submit_names_timesheets' + messageIdSuffix},
										{name: selectedPerson.firstName}
								  )
								: intl.formatMessage({id: 'time_approval.submit_timesheets' + messageIdSuffix}),
					  }
					: undefined,
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.VERYLIGHTGREY,
				callback: submitTimesheets,
				disabled: isFullyLocked,
				dataCy: 'submit-timesheets-button',
				userpilot: 'submit-timesheets-button',
			};
			rightContent.push(submitTimesheetButton);
		}

		if (usingTimeOffApproval) {
			const submitTimeOffButton = {
				type: ELEMENT_TYPE.BUTTON,
				text: groupLocked
					? intl.formatMessage({id: 'time_approval.submit_names_time_off'}, {name: selectedPerson.firstName})
					: intl.formatMessage({id: 'time_approval.submit_time_off'}),
				tooltipProps: {
					tooltipDuration: 30000,
					canBeMiddle: true,
					tooltipPosition: 'bottom',
					infoText: groupLocked
						? intl.formatMessage(
								{id: 'time_off_approval.submit_names_timesheets_info'},
								{name: selectedPerson.firstName}
						  )
						: intl.formatMessage({id: 'time_off_approval.submit_timesheets_info'}),
				},
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.VERYLIGHTGREY,
				callback: submitTimeOff,
				disabled: false,
				dataCy: 'submit-time-off-button',
			};
			rightContent.push(submitTimeOffButton);
		}

		const theEye = {
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			expandLeft: true,
			onSelect: handleTheEyeOptionSelect,
		};
		rightContent.push(theEye);

		return <HeaderBar leftContent={leftContent} centerContent={centerContent} rightContent={rightContent} />;
	};

	return (
		<BottomSectionWrapperOld data-userpilot="timesheets-task-list">
			<ButtonGroupHeader
				intl={intl}
				selectedSwitch={selectedSwitch}
				setSelectedSwitch={setSelectedSwitch}
				groupLocked={groupLocked || isFullyLocked}
			/>
			<BottomSectionSubHeader>{getHeader()}</BottomSectionSubHeader>
			<ForecastQueryRenderer
				key="query-render-TimesheetTableWrapper"
				query={TimesheetTableWrapperQuery}
				customLoader={() => <InlineLoader />}
				variables={{
					taskTypeSearchQuery: taskTypeSearchQuery,
					fetchSwitchTasks: fetchSwitchTasks,
					sortValue: 'project-id',
					fetchSuggestedTasks: fetchSuggestedTasks,
					timePeriod: timePeriod,
					currentViewingDate: currentViewingDate.format('YYYY-MM-DD'),
					personId: selectedPerson.id,
				}}
				render={(relayProps, retry) => {
					return (
						<TimesheetTableWrapper
							{...relayProps}
							retry={retry}
							selectedPerson={selectedPerson}
							selectedSwitch={selectedSwitch}
							enabledColumns={enabledColumns}
							timeRegistrations={timeRegistrations}
							timeRegsForPeriod={timeRegsForPeriod}
							weekStart={weekStart}
							weekEnd={weekEnd}
							currentViewingDate={currentViewingDate}
							timePeriod={timePeriod}
							searchValue={searchValue}
							selectDayFromCalendar={selectDayFromCalendar}
							holidays={memoizedHolidays}
							lockedDate={lockedDate}
							isFullyLocked={isFullyLocked}
							usingTimeApproval={usingTimeApproval}
							usingInternalTimeApproval={usingInternalTimeApproval}
							usingTimeOffApproval={usingTimeOffApproval}
							periodTimeApprovalStatus={periodTimeApprovalStatus}
							overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
							timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData}
						/>
					);
				}}
			/>
		</BottomSectionWrapperOld>
	);
};

export default timesheetsBottomSection;
