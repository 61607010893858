import React from 'react';
import Styled from 'styled-components';
import {Card} from 'web-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {FavouredActionWrapper} from '../../hooks/favoured_action_wrapper';
import Moment from 'moment';
import LabelGroup from '../labels/label_group';
import {ImageComponent} from '../images/ImageComponent';
import GenericTaskContextMenu from '../context-menus/generic_task_context_menu';
import TimeRegPopup from '../popups/time_reg_popup';
import {injectIntl} from 'react-intl';
import Util from '../../util/util';
import DirectApi, {profilePicSrc} from '../../../../directApi';
import DeprecatedProjectIndicatorJS from '../project-indicator/js/DeprecatedProjectIndicatorJS';

export class DraggableCard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			contextMenuPosition: null,
			isClientActionsRestricted: Util.isClientTaskActionsRestricted(this.props.viewer),
		};

		this.updateCardHeight = this.updateCardHeight.bind(this);
	}

	componentDidMount() {
		this.updateCardHeight();
	}

	updateCardHeight(imageHeight = 0) {
		if (this.props.updateCardHeight && this.card) {
			this.props.updateCardHeight(this.props.task.id, this.card.clientHeight + imageHeight);
		}
	}

	openContextMenu(e) {
		e.preventDefault();
		const contextMenuPosition = {};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			contextMenuPosition.x = btnBounds.right - btn.clientWidth - 2;
			if (window.innerHeight - btnBounds.bottom < 250) {
				contextMenuPosition.y = btnBounds.bottom - 282;
			} else {
				contextMenuPosition.y = btnBounds.bottom + 2;
			}
		} else {
			contextMenuPosition.x = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				contextMenuPosition.y = e.pageY - 250;
			} else {
				contextMenuPosition.y = e.pageY;
			}
		}
		this.setState({contextMenuPosition: contextMenuPosition});
	}

	closeContextMenu() {
		this.setState({contextMenuPosition: null});
	}

	getContextMenu(task) {
		return (
			<GenericTaskContextMenu
				cy="draggable-card-task-context-menu"
				task={task}
				isConnectedParent={this.props.isConnectedParent}
				viewer={this.props.viewer}
				contextMenuPosition={this.state.contextMenuPosition}
				closeContextMenu={() => this.closeContextMenu()}
			/>
		);
	}

	/**
	 * Task is missing a property in task.project that the task in allTasks has
	 * So if the two tasks are the same then merge them into a new object
	 * @param task
	 * @returns {*|(*)}
	 */
	getTimeRegTask(task) {
		if (this.props.viewer.company.allTasks?.edges) {
			const taskEdge = this.props.viewer.company.allTasks?.edges.find(edge => edge.node.id === task.id);
			if (taskEdge) return {...task, ...taskEdge.node};
		}
		return task;
	}

	render() {
		let {task, onClick, cy, theEyeOptions, showContext, viewer} = this.props;
		const {isClientActionsRestricted} = this.state;
		const cardLocked = task.readOnly?.isReadOnly || isClientActionsRestricted;
		const parentIsEpic = task.parentTask && task.parentTask.taskType ? task.parentTask.taskType === 'EPIC' : false;
		const parentTaskName = task.parentTask && !parentIsEpic ? task.parentTask.name : null;
		const clientName = task.project.client?.name;

		if (!task.project) return null;
		const hasDependencies = task => {
			return task.thisTaskDependsOn.edges.length > 0 || task.dependsOnThisTask.edges.length > 0;
		};

		let filteredAssignees = task.assignedPersons;

		if (Util.isClientTaskViewRestricted(viewer)) {
			filteredAssignees = filteredAssignees.filter(person => person.id === viewer.actualPersonId);
		}

		const assignees = filteredAssignees.map(person => ({
			fullName: person.fullName,
			imageSource: profilePicSrc(person.profilePictureId),
		}));

		return (
			<CardContainer key={task.id} ref={div => (this.card = div)}>
				<Card
					showProgressBar={this.props.showProgressBar}
					intl={this.props.intl}
					assignees={assignees}
					progress={task.progress}
					showCheckbox={!cardLocked && this.props.showCheckbox}
					cardDisabled={cardLocked}
					isSelected={this.props.isSelected}
					onCheckboxClick={e =>
						this.props.isSelected ? this.props.onDeselect(task.id) : this.props.onSelect({node: task}, e.shiftKey)
					}
					theEyeOptions={theEyeOptions}
					id={task.companyTaskId}
					name={task.name}
					highPriority={task.highPriority}
					isBug={task.bug}
					showBlocked={task.blocked}
					isFavoured={task.favoured}
					onCardClick={onClick}
					hasDependencies={hasDependencies(task)}
					imageComponent={
						task.coverFile && (
							<ImageComponent
								updateCardHeight={this.updateCardHeight}
								taskId={task.id}
								imgSizeMapRef={this.props.imgSizeMapRef}
								imageSrc={task.coverFile && DirectApi.fileSrc(task.coverFile.id)}
							/>
						)
					}
					starIcon={({isFavoured}) => <FavouredActionWrapper isFavoured={isFavoured} taskId={task.id} />}
					projectIndicatorIcon={() =>
						this.props.showProjectIndicator ? <DeprecatedProjectIndicatorJS project={task.project} /> : null
					}
					showProjectIndicator={this.props.showProjectIndicator}
					deadline={
						task.deadlineDay && Moment({y: task.deadlineYear, M: task.deadlineMonth - 1, d: task.deadlineDay})
					}
					labels={task.taskLabels.length > 0 && (() => <LabelGroup labels={task.taskLabels} />)}
					todosSubtasksCount={task.subTaskCount}
					attachmentsCount={task.fileCount}
					commentsCount={task.commentCount}
					onContextMenu={e => this.openContextMenu(e)}
					showContext={!cardLocked && showContext}
					showTimeReg={!cardLocked && this.props.showTimeReg}
					timeRegPopupElement={() => (
						<TimeRegPopup
							viewer={viewer}
							projectId={task.project.id}
							task={this.getTimeRegTask(task)}
							harvestTask={false}
						/>
					)}
					contextMenuElement={() => (this.state.contextMenuPosition ? this.getContextMenu(task) : null)}
					cy={cy}
					parentTaskName={parentTaskName}
					clientName={clientName}
				/>
			</CardContainer>
		);
	}
}

const CardContainer = Styled.div`
	min-height: 10px;
`;

export default injectIntl(
	createFragmentContainer(DraggableCard, {
		task: graphql`
			fragment DraggableCard_task on Task {
				id
				billable
				latestUiUpdateAt
				description
				timeLeft
				progress
				favouredSortOrder
				estimateForecastMinutes
				totalMinutesRegistered
				companyTaskId
				readOnly {
					isReadOnly
				}
				userCanDeleteTask
				userCantDeleteTaskReason
				name
				favoured
				highPriority
				bug
				blocked
				startDay
				startMonth
				startYear
				deadlineDay
				deadlineMonth
				jiraId
				vstsId
				deadlineYear
				subTaskCount
				fileCount
				commentCount
				done
				parentTaskId
				hasInvoicedTime
				role {
					id
					name
				}
				parentTask {
					name
					taskType
				}
				statusColumnV2 {
					id
					category
					projectGroupStatusColumnId
				}
				taskLabels {
					id
					label {
						id
						name
						color
					}
				}
				coverFile {
					id
					name
					key
				}
				project {
					id
					companyProjectId
					projectColor
					name
					status
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					projectGroupId
					unit4Project {
						activitiesEnabled
					}
					harvestProject {
						id
					}
					client {
						name
						logoId
						logoDefaultId
					}
					isProgramRevenueLocked
					programBudgetType
					...DeprecatedProjectIndicatorJS_project
				}
				thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
					edges {
						node {
							id
							type
							thisDependsOnTask {
								statusColumnV2 {
									category
								}
							}
						}
					}
				}
				dependsOnThisTask(first: 1000) @connection(key: "Task_dependsOnThisTask") {
					edges {
						node {
							id
							type
							taskDependsOnThis {
								id
								statusColumnV2 {
									category
								}
							}
						}
					}
				}
				assignedPersons {
					id
					firstName
					lastName
					fullName
					email
					profilePictureId
					profilePictureDefaultId
					active
					permissions
					role {
						id
						name
					}
				}
			}
		`,
	})
);
