import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {VersionReloadRoute} from '../../containers/version_reload_route';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';
import SettingsCompany, {settingsCompanyQuery} from './company-details-page/settings_company';
import SettingsCompanyNotifications, {
	SettingsCompanyNotificationsQuery,
} from './notification-settings-page/settings_company_notifications';
import SettingsRoles, {settingsRolesQuery} from './resources-page/settings_roles';
import SettingsSkills, {settingsSkillsQuery} from './resources-page/settings_skills';
import {getModuleAuthFunc, hasModule} from '../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../constants';
import SettingsPermissions, {settingsPermissionsQuery} from './permissions-page/settings_permissions';
import SettingsLabelsPage, {SettingsLabelsPageQuery} from './labels-page/settings_labels_page';
import SettingsSubscriptionV2, {settingsSubscriptionQuery} from './settings_subscription';
import SettingsIdleTimes, {settingsIdleTimesQuery} from './time-management/settings_idle_times';
import {SettingsPeopleContextProvider} from './resources-page/SettingsPeopleContext';
import SettingsPeoplePage, {SettingsPeoplePageQuery} from './resources-page/SettingsPeoplePage';
import SettingsTeams, {settingsTeamsQuery} from './resources-page/settings_teams';
import SettingsDepartments, {settingsDepartmentsQuery} from './resources-page/settings_departments';
import SettingsProfilePerson, {settingsProfilePersonQuery} from './settings_profile_person';
import SettingsClientGuestUsers, {SettingsClientGuestUsersQuery} from './clients-page/SettingsClientGuestUsers';
import {hasFeatureFlag} from '../shared/util/FeatureUtil';
import SettingsClientInformation, {SettingsClientInformationQuery} from './clients-page/SettingsClientInformation';
import SettingsClient, {settingsClientQuery} from './clients-page/client-js/settings_client';
import SettingsClients, {settingsClientsQuery} from './clients-page/client-js/settings_clients';
import SettingsPriorityLevels, {
	SettingsPriorityLevelsQuery,
} from '../../containers/settings/priority-levels/SettingsPriorityLevels';
import SettingsHolidayCalendar, {settingsHolidayCalendarQuery} from './holidays-page/settings_holiday_calendar';
import SettingsHolidayCalendars, {settingsHolidayCalendarsQuery} from './holidays-page/settings_holiday_calendars';
import SettingsApiKeys, {settingsApiKeysQuery} from './api-keys-page/settings_api_keys';
import SettingsDataImporter, {settingsDataImporterQuery} from './data-importer-page/settings_data_importer';
import Util from '../shared/util/util';
import SettingsCustomFields, {SettingsCustomFieldsQuery} from '../../containers/settings/custom-fields/SettingsCustomFields';
import SettingsFinanceBillTo, {SettingsFinanceBillToQuery} from '../../components/settings-finance/SettingsFinanceBillTo';
import SettingsFinance, {settingsFinanceQuery} from '../../containers/settings/settings_finance';
import AppHarvest, {appHarvestQuery} from '../../containers/settings/app_catalog/app_harvest';
import AppAsana, {appAsanaQuery} from '../../containers/settings/app_catalog/app_asana';
import AppTrello, {appTrelloQuery} from '../../containers/settings/app_catalog/app_trello';
import AppSlack, {appSlackQuery} from '../../containers/settings/app_catalog/app_slack';
import AppGithub, {appGithubQuery} from '../../containers/settings/app_catalog/app_github';
import AppVsts, {appVstsQuery} from '../../containers/settings/app_catalog/app_vsts';
import AppGitlab, {appGitlabQuery} from '../../containers/settings/app_catalog/app_gitlab';
import AppJira, {appJiraQuery} from '../../containers/settings/app_catalog/app_jira/app_jira';
import AppJiraServer, {appJiraServerQuery} from '../../containers/settings/app_catalog/app_jira_server';
import AppGoogleDrive, {appGoogleDriveQuery} from '../../containers/settings/app_catalog/app_google_drive';
import AppOkta, {appOktaQuery} from '../../containers/settings/app_catalog/app_okta';
import AppOnelogin, {appOneloginQuery} from '../../containers/settings/app_catalog/app_onelogin';
import AppAzureActiveDirectory, {
	appAzureActiveDirectoryQuery,
} from '../../containers/settings/app_catalog/app_azure_active_directory';
import AppGoSimplo from '../../containers/settings/app_catalog/AppGoSimplo';
import AppXero, {appXeroQuery} from '../../containers/settings/app_catalog/app_xero';
import AppTeams, {appTeamsQuery} from '../../containers/settings/app_catalog/app_teams';
import AppQuickbooks, {appQuickbooksQuery} from '../../containers/settings/app_catalog/app_quickbooks';
import AppSageIntacct, {appSageIntacctQuery} from '../../containers/settings/app_catalog/app_sage_intacct';
import AppEconomic, {appEconomicQuery} from '../../containers/settings/app_catalog/app_economic';
import SalesforceConfigurationConductor, {
	salesforceConfigurationConductorQuery,
} from '../../containers/settings/app_catalog/SalesforceConfigurationConductor';
import AppUnit4, {appUnit4Query} from '../../containers/settings/app_catalog/app_unit4';
import AppHubspot, {appHubspotQuery} from '../../containers/settings/app_catalog/app_hubspot';
import AppPipedrive, {appPipedriveQuery} from '../../containers/settings/app_catalog/app_pipedrive';
import AppBambooHR, {appBambooHRQuery} from '../../containers/settings/app_catalog/AppBambooHR';
import AppZapier from '../../containers/settings/app_catalog/app_zapier';
import AppCalendar, {appCalendarQuery} from '../../containers/settings/app_catalog/app_calendar';
import SettingsAppCatalog, {settingsAppCatalogQuery} from '../../containers/settings/settings_app_catalog';
import SettingsSetup, {SettingsSetupQuery} from '../../containers/settings/setup/SettingsSetup';
import AppGoogleSSO, {AppGoogleSSOQuery} from '../../containers/settings/app_catalog/AppGoogleSSO';

const AdminRoutes = ({actualPersonId}) => {
	let {path} = useRouteMatch();

	return (
		<Switch>
			<VersionReloadRoute path={`${path}/details`}>
				<ForecastQueryRenderer
					key="query-render-settingsCompany"
					query={settingsCompanyQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsCompany retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<Redirect from={`${path}/team-notifications`} to={`${path}/notifications`} />
			{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
				<VersionReloadRoute path={`${path}/notifications`}>
					<ForecastQueryRenderer
						key="query-render-settingsCompanyNotificationsQuery"
						query={SettingsCompanyNotificationsQuery}
						authorizeAccessRoute={'settings'}
						render={(relayProps, retry) => {
							return <SettingsCompanyNotifications retry={retry} {...relayProps} />;
						}}
					/>
				</VersionReloadRoute>
			)}
			<VersionReloadRoute path={`${path}/roles`}>
				<ForecastQueryRenderer
					key="query-render-settingsRoles"
					query={settingsRolesQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsRoles retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/skills`}>
				<ForecastQueryRenderer
					key="query-render-settingsSkills"
					query={settingsSkillsQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsSkills retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			{hasModule(MODULE_TYPES.PERMISSION_MANAGEMENT) && (
				<VersionReloadRoute path={`${path}/permissions`}>
					<ForecastQueryRenderer
						key="query-render-settingsPermissions"
						query={settingsPermissionsQuery}
						authorizeAccessRoute={'settings'}
						render={(relayProps, retry) => {
							return <SettingsPermissions retry={retry} {...relayProps} />;
						}}
					/>
				</VersionReloadRoute>
			)}
			<VersionReloadRoute path={`${path}/labels`}>
				<ForecastQueryRenderer
					key="query-render-settingsLabels"
					query={SettingsLabelsPageQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => <SettingsLabelsPage retry={retry} {...relayProps} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/subscription`}>
				<ForecastQueryRenderer
					key="query-render-settingsSubscription"
					query={settingsSubscriptionQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						if (
							relayProps &&
							relayProps.viewer &&
							relayProps.viewer.company &&
							relayProps.viewer.company.isChargebeeCustomer === false
						) {
							return <Redirect to={`${path}/details`} />;
						}
						return <SettingsSubscriptionV2 retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<Redirect from={`${path}/idle-times`} to={`${path}/time-management`} />
			<VersionReloadRoute path={`${path}/time-management`}>
				<ForecastQueryRenderer
					key="query-render-settingsIdleTimes"
					query={settingsIdleTimesQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsIdleTimes retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/people`}>
				<SettingsPeopleContextProvider actualPersonId={actualPersonId}>
					<ForecastQueryRenderer
						key="query-render-settingsPeoplePage"
						query={SettingsPeoplePageQuery}
						authorizeAccessRoute={'settings'}
						render={(relayProps, retry) => {
							return <SettingsPeoplePage retry={retry} {...relayProps} />;
						}}
					/>
				</SettingsPeopleContextProvider>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/teams`}>
				<ForecastQueryRenderer
					key="query-render-settingsTeams"
					query={settingsTeamsQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsTeams retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/departments`}>
				<ForecastQueryRenderer
					key="query-render-settingsDepartments"
					query={settingsDepartmentsQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.DEPARTMENTS)}
					render={(relayProps, retry) => {
						return <SettingsDepartments retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute
				path={`${path}/team/view-user/:userId`}
				children={({match}) => {
					return (
						<ForecastQueryRenderer
							key="query-render-settingsProfilePerson"
							query={settingsProfilePersonQuery}
							variables={match.params}
							authorizeAccessRoute={'settings'}
							render={(relayProps, retry) => {
								return <SettingsProfilePerson retry={retry} {...relayProps} />;
							}}
						/>
					);
				}}
			/>
			<VersionReloadRoute
				path={`${path}/clients/view-client/:clientId/guest-users`}
				children={({match}) => (
					<ForecastQueryRenderer
						key="query-render-settingsClientGuestUsers"
						query={SettingsClientGuestUsersQuery}
						variables={match.params}
						authorizeAccessRoute={'settings'}
						render={relayProps => {
							return <SettingsClientGuestUsers {...relayProps} />;
						}}
					/>
				)}
			/>
			<VersionReloadRoute
				path={`${path}/clients/view-client/:clientId`}
				children={({match}) =>
					hasFeatureFlag('invoicing_pathways') ? (
						<ForecastQueryRenderer
							key="query-render-settingsClient"
							query={SettingsClientInformationQuery}
							variables={match.params}
							authorizeAccessRoute={'settings'}
							render={(relayProps, retry) => {
								return <SettingsClientInformation retry={retry} {...relayProps} isProjectClientPage={false} />;
							}}
						/>
					) : (
						<ForecastQueryRenderer
							key="query-render-settingsClient"
							query={settingsClientQuery}
							variables={match.params}
							authorizeAccessRoute={'settings'}
							render={relayProps => {
								return <SettingsClient {...relayProps} />;
							}}
						/>
					)
				}
			/>
			<VersionReloadRoute path={`${path}/clients`}>
				<ForecastQueryRenderer
					key="query-render-settingsClients"
					query={settingsClientsQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsClients {...relayProps} retry={retry} />;
					}}
				/>
			</VersionReloadRoute>
			<Redirect from={`${path}/priority-levels`} to={`${path}/project-priority`} />
			<VersionReloadRoute path={`${path}/project-priority`}>
				<ForecastQueryRenderer
					key="query-render-SettingsPriorityLevels"
					query={SettingsPriorityLevelsQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsPriorityLevels {...relayProps} retry={retry} />;
					}}
				/>
			</VersionReloadRoute>
			<Redirect
				from={`${path}/holiday-calendars/view-holiday-calendar/:holidayCalendarId`}
				to={`${path}/holidays/view-holiday-calendar/:holidayCalendarId`}
			/>
			<VersionReloadRoute
				path={`${path}/holidays/view-holiday-calendar/:holidayCalendarId`}
				children={({match}) => {
					return (
						<ForecastQueryRenderer
							key="query-render-settingsHolidayCalendar"
							query={settingsHolidayCalendarQuery}
							variables={match.params}
							authorizeAccessRoute={'settings'}
							render={(relayProps, retry) => {
								return <SettingsHolidayCalendar retry={retry} {...relayProps} />;
							}}
						/>
					);
				}}
			/>
			<Redirect from={`${path}/holiday-calendars`} to={`${path}/holidays`} />
			<VersionReloadRoute path={`${path}/holidays`}>
				<ForecastQueryRenderer
					key="query-render-settingsHolidayCalendars"
					query={settingsHolidayCalendarsQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsHolidayCalendars retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/api-keys`}>
				<ForecastQueryRenderer
					query={settingsApiKeysQuery}
					key="query-render-settingsApiKeys"
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => <SettingsApiKeys retry={retry} {...relayProps} />}
				/>
			</VersionReloadRoute>
			{hasModule(MODULE_TYPES.DATA_IMPORTER) && (
				<VersionReloadRoute path={`${path}/data-importer`}>
					<ForecastQueryRenderer
						query={settingsDataImporterQuery}
						componentName="settings_data_importer"
						key="query-render-settingsDataImporter"
						showLoader={true}
						authorizeAccessRoute={'settings'}
						render={(relayProps, retry) => <SettingsDataImporter retry={retry} {...relayProps} />}
					/>
				</VersionReloadRoute>
			)}
			{
				// TODO: continue move files
			}
			{Util.hasCustomFields() && (
				<VersionReloadRoute path={`${path}/custom-fields`}>
					<ForecastQueryRenderer
						query={SettingsCustomFieldsQuery}
						key="query-render-settingsApiKeys"
						authorizeAccessRoute={'settings'}
						render={(relayProps, retry) => <SettingsCustomFields retry={retry} {...relayProps} />}
					/>
				</VersionReloadRoute>
			)}
			<VersionReloadRoute path={`${path}/finance/bill-to`}>
				<ForecastQueryRenderer
					key="query-render-SettingsFinanceBillTo"
					query={SettingsFinanceBillToQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.FINANCE)}
					render={(relayProps, retry) => {
						return <SettingsFinanceBillTo retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/finance`}>
				<ForecastQueryRenderer
					key="query-render-settingsFinance"
					query={settingsFinanceQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.FINANCE)}
					render={(relayProps, retry) => {
						return <SettingsFinance retry={retry} {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<Redirect from={`${path}/catalog/:integration`} to={`${path}/integrations/:integration`} />
			<VersionReloadRoute path={`${path}/integrations/harvest`}>
				<ForecastQueryRenderer
					key="query-render-appHarvest"
					query={appHarvestQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppHarvest {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			{hasModule(MODULE_TYPES.ASANA) && (
				<VersionReloadRoute path={`${path}/integrations/asana`}>
					<ForecastQueryRenderer
						key="query-render-appAsana"
						query={appAsanaQuery}
						authorizeAccessRoute={'settings'}
						render={relayProps => {
							return <AppAsana {...relayProps} />;
						}}
					/>
				</VersionReloadRoute>
			)}
			<VersionReloadRoute path={`${path}/integrations/trello`}>
				<ForecastQueryRenderer
					key="query-render-appTrello"
					query={appTrelloQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppTrello {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/slack`}>
				<ForecastQueryRenderer
					key="query-render-appCalendar"
					query={appSlackQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppSlack {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/github`}>
				<ForecastQueryRenderer
					key="query-render-appGithub"
					query={appGithubQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppGithub {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={[`${path}/integrations/vsts`, `${path}/integrations/azure-devops`]}>
				<ForecastQueryRenderer
					key="query-render-appVstsQuery"
					query={appVstsQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => <AppVsts {...relayProps} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/gitlab`}>
				<ForecastQueryRenderer
					key="query-render-appGitlab"
					query={appGitlabQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => <AppGitlab {...relayProps} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/jira-verified`}>
				<ForecastQueryRenderer
					key="query-render-appJiraQuery"
					query={appJiraQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => <AppJira {...relayProps} showVerifiedPrompt={true} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/jira`}>
				<ForecastQueryRenderer
					key="query-render-appJiraQuery"
					query={appJiraQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => <AppJira {...relayProps} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/jira-server`}>
				<ForecastQueryRenderer
					key="query-render-appJiraServerQuery"
					query={appJiraServerQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => <AppJiraServer {...relayProps} />}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={[`${path}/integrations/gdrive`, `${path}/integrations/google-drive`]}>
				<ForecastQueryRenderer
					key="query-render-appGoogleDrive"
					query={appGoogleDriveQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppGoogleDrive {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/okta`}>
				<ForecastQueryRenderer
					key="query-render-appOkta"
					query={appOktaQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppOkta {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/onelogin`}>
				<ForecastQueryRenderer
					key="query-render-appOnelogin"
					query={appOneloginQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppOnelogin {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/google-sso`}>
				<ForecastQueryRenderer
					key="query-render-appGoogleSSO"
					query={AppGoogleSSOQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppGoogleSSO {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={[`${path}/integrations/azureAD`, `${path}/integrations/azure-active-directory`]}>
				<ForecastQueryRenderer
					key="query-render-appAzureActiveDirectory"
					query={appAzureActiveDirectoryQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppAzureActiveDirectory {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<Route path={`${path}/integrations/gosimplo`} component={AppGoSimplo} />
			<VersionReloadRoute path={`${path}/integrations/xero`}>
				<ForecastQueryRenderer
					key="query-render-appXero"
					query={appXeroQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppXero {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/teams`}>
				<ForecastQueryRenderer
					key="query-render-appTeams"
					query={appTeamsQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppTeams {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/quickbooks`}>
				<ForecastQueryRenderer
					key="query-render-appQuickbooks"
					query={appQuickbooksQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppQuickbooks {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/sage-intacct`}>
				<ForecastQueryRenderer
					key="query-render-appQuickbooks"
					query={appSageIntacctQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppSageIntacct {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={[`${path}/integrations/economic`, `${path}/integrations/e-conomic`]}>
				<ForecastQueryRenderer
					key="query-render-appEconomic"
					query={appEconomicQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppEconomic {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/salesforce`}>
				<ForecastQueryRenderer
					key="query-render-salesforceConfigurationConductor"
					query={salesforceConfigurationConductorQuery}
					render={relayProps => {
						return <SalesforceConfigurationConductor {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/unit4`}>
				<ForecastQueryRenderer
					key="query-render-appUnit4"
					query={appUnit4Query}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppUnit4 {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/hubspot`}>
				<ForecastQueryRenderer
					key="query-render-appHubspot"
					query={appHubspotQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.HUBSPOT)}
					render={relayProps => {
						return <AppHubspot {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/pipedrive`}>
				<ForecastQueryRenderer
					key="query-render-apppipedrive"
					query={appPipedriveQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.PIPEDRIVE)}
					render={relayProps => {
						return <AppPipedrive {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/bamboohr`}>
				<ForecastQueryRenderer
					key="query-render-appbamboohr"
					query={appBambooHRQuery}
					authorizeAccessRoute={'settings'}
					authorizeModule={getModuleAuthFunc(MODULE_TYPES.BAMBOOHR)}
					render={relayProps => {
						return <AppBambooHR {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations/zapier`} component={AppZapier} />
			<VersionReloadRoute path={`${path}/integrations/calendar`}>
				<ForecastQueryRenderer
					key="query-render-appCalendar"
					query={appCalendarQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <AppCalendar {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/integrations`}>
				<ForecastQueryRenderer
					key="query-render-settingsAppCatalog"
					query={settingsAppCatalogQuery}
					authorizeAccessRoute={'settings'}
					render={relayProps => {
						return <SettingsAppCatalog {...relayProps} />;
					}}
				/>
			</VersionReloadRoute>
			<VersionReloadRoute path={`${path}/setup`}>
				<ForecastQueryRenderer
					key="query-render-SettingsSetup"
					query={SettingsSetupQuery}
					authorizeAccessRoute={'settings'}
					render={(relayProps, retry) => {
						return <SettingsSetup {...relayProps} retry={retry} />;
					}}
				/>
			</VersionReloadRoute>
		</Switch>
	);
};

export default AdminRoutes;
