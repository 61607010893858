/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignPersonToTaskInput = {|
  taskId?: ?string,
  personId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type assignPersonToTaskMutationVariables = {|
  input: AssignPersonToTaskInput
|};
export type assignPersonToTaskMutationResponse = {|
  +assignPersonToTask: ?{|
    +task: ?{|
      +id: string,
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |}
  |}
|};
export type assignPersonToTaskMutation = {|
  variables: assignPersonToTaskMutationVariables,
  response: assignPersonToTaskMutationResponse,
|};
*/


/*
mutation assignPersonToTaskMutation(
  $input: AssignPersonToTaskInput!
) {
  assignPersonToTask(input: $input) {
    task {
      id
      assignedPersons {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignPersonToTaskInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignPersonToTaskPayload",
    "kind": "LinkedField",
    "name": "assignPersonToTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "assignedPersons",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignPersonToTaskMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignPersonToTaskMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "assignPersonToTaskMutation",
    "operationKind": "mutation",
    "text": "mutation assignPersonToTaskMutation(\n  $input: AssignPersonToTaskInput!\n) {\n  assignPersonToTask(input: $input) {\n    task {\n      id\n      assignedPersons {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef5d55843a60cbe3c15082272ab74bc6';

module.exports = node;
