import ReportTable from './ReportsTable';
import React, { useState } from 'react';
import { Heading } from '@forecast-it/design-system';
const ReportsTabContent = ({ company, reports, search, groupings, scrollRef, groupingColumnHeader, }) => {
    const [filteredReports, setFilteredReports] = useState([]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, { size: '4' },
            "Showing (",
            filteredReports.length,
            " of ",
            reports.length,
            ")"),
        React.createElement(ReportTable, { company: company, reports: reports, search: search, groupings: groupings, scrollRef: scrollRef, setFilteredRows: setFilteredReports, groupingColumnHeader: groupingColumnHeader })));
};
export default ReportsTabContent;
