import { partition } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import { withSocketHandling } from '../../../../../../socket/withSocketHandling';
import { getSocketConfig } from '../../../timesheet_socket_handling';
import { TIME_PERIOD } from '../../../timesheets_change_date_view';
import { buildWeekArr } from '../../time_row_utils';
import { getCombinedSwitchList, getEntityList, getGroupedMovedTasks, getTimeRegsMoved, } from '../timesheet-table-util/DataBundlingUtil';
import { getDayRegsForSwitchEntity, sortList } from '../timesheet-table-util/DataUtil';
import TimesheetTable from '../timesheets_time_row_table';
function getQueryTasksFromViewer(viewer) {
    var _a, _b, _c;
    return (_c = (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.taskTypeQueryTasks) === null || _b === void 0 ? void 0 : _b.edges) !== null && _c !== void 0 ? _c : [];
}
function flattenQueryTasks(queryTasks) {
    return queryTasks.reduce((acc, task) => {
        if (task && task.node) {
            acc.push(task.node);
        }
        return acc;
    }, []);
}
function partitionQueryTasksWithTimeRegs(queryTasksFlat, timeRegsForPeriod) {
    const timeRegistrationTaskIdsSet = new Set(timeRegsForPeriod.filter(tReg => { var _a; return !!((_a = tReg === null || tReg === void 0 ? void 0 : tReg.node) === null || _a === void 0 ? void 0 : _a.task); }).map(tReg => tReg.node.task.id));
    return partition(queryTasksFlat, task => timeRegistrationTaskIdsSet.has(task.id));
}
const TimesheetThisWeeksWorkTableWrapper = ({ viewer, relay, retry, setSocketVariables, setSocketConfig, selectedPerson, enabledColumns, timeRegistrations, timeRegsForPeriod, weekStart, weekEnd, currentViewingDate, timePeriod, filterActive, searchActive, usingCurrentUserAssigneeFilter, sort, selectDayFromCalendar, holidays, lockedDate, isFullyLocked, usingTimeApproval, usingInternalTimeApproval, usingTimeOffApproval, periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData, paginationRef, goToTimesheetsTab, }) => {
    var _a, _b;
    const history = useHistory();
    const intl = useIntl();
    const [movedTasks, setMovedTasks] = useState([]);
    const fetching = useRef(false);
    const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
    const weekDays = isWeekView ? buildWeekArr(currentViewingDate.clone().endOf('week'), 7) : [];
    /* FIXME: Sorting is currently not aligned between front-end and back-end so results of loadMore will not appear at the bottom of the list */
    const loadMore = (pageSize, callback, options) => {
        var _a, _b, _c;
        relay.loadMore(pageSize, callback, options);
        const taskCount = ((_c = (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.taskTypeQueryTasks) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.length) || 0;
        setSocketVariables(taskCount + pageSize);
    };
    const hasNextPage = (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.taskTypeQueryTasks) === null || _b === void 0 ? void 0 : _b.pageInfo.hasNextPage;
    const fetchMoreTasks = () => {
        const onSuccess = () => {
            fetching.current = false;
            return;
        };
        if (hasNextPage && !fetching.current) {
            fetching.current = true;
            loadMore(30, onSuccess, {});
        }
    };
    useImperativeHandle(paginationRef, () => ({
        loadMore() {
            fetchMoreTasks();
        },
    }), [hasNextPage]);
    useEffect(() => {
        const selectedPersonIdInt = parseInt(atob(selectedPerson.id).replace('Person:', ''));
        setSocketConfig(getSocketConfig([selectedPersonIdInt]), relay.refetchConnection);
    }, [selectedPerson]);
    const queryTasks = flattenQueryTasks(getQueryTasksFromViewer(viewer));
    const [queryTasksWithTimeRegistrations, queryTasksWithoutTimeRegistrations] = partitionQueryTasksWithTimeRegs(queryTasks, timeRegsForPeriod);
    const queryTasksWithTimeRegistrationsIds = queryTasksWithTimeRegistrations.map(task => task.id).concat(movedTasks);
    const _timeRegsMoved = getTimeRegsMoved(timeRegsForPeriod, queryTasksWithTimeRegistrationsIds, []);
    const _groupedMovedTasks = getGroupedMovedTasks(isWeekView, _timeRegsMoved);
    const combinedTaskList = getCombinedSwitchList(isWeekView, _groupedMovedTasks, queryTasksWithoutTimeRegistrations, [], _timeRegsMoved);
    const topSection = sortList(combinedTaskList, sort.value, sort.ascending);
    const _getDayRegsForSwitchEntity = entity => {
        return getDayRegsForSwitchEntity(isWeekView, _groupedMovedTasks, entity);
    };
    const taskCountRef = useRef(-1);
    const taskCount = (queryTasks === null || queryTasks === void 0 ? void 0 : queryTasks.length) || 0;
    if (taskCountRef.current === -1 && taskCount === 0) {
        taskCountRef.current = 0;
        trackEvent('This Weeks Work', 'Empty List', {
            filterActive,
            searchActive,
            usingCurrentUserAssigneeFilter,
        });
    }
    const entityList = getEntityList([], [], sort.value, sort.ascending, isWeekView, weekDays, topSection, _getDayRegsForSwitchEntity);
    return (React.createElement(TimesheetTable, { entityList: entityList, externalMovedTasks: movedTasks, externalSortValue: sort.value, externalSortAscending: sort.ascending, setExternalSortValue: sort.setValue, setExternalSortAscending: sort.setAscending, setExternalMovedTasks: setMovedTasks, loading: false, intl: intl, history: history, viewer: viewer, relay: relay, retry: retry, selectedPerson: selectedPerson, selectedSwitch: null, enabledColumns: enabledColumns, timeRegistrations: timeRegistrations, timeRegsForPeriod: timeRegsForPeriod, weekStart: weekStart, weekEnd: weekEnd, currentViewingDate: currentViewingDate, timePeriod: timePeriod, filterActive: filterActive, searchActive: searchActive, usingCurrentUserAssigneeFilter: usingCurrentUserAssigneeFilter, selectDayFromCalendar: selectDayFromCalendar, holidays: holidays, lockedDate: lockedDate, isFullyLocked: isFullyLocked, usingTimeApproval: usingTimeApproval, usingInternalTimeApproval: usingInternalTimeApproval, usingTimeOffApproval: usingTimeOffApproval, periodTimeApprovalStatus: periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId: overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData: timeRegistrationTargetSuggestionsData, goToTimesheetsTab: goToTimesheetsTab, thisWeekWork: true }));
};
const TimesheetThisWeeksWorkTableWrapperQuery = graphql `
	query TimesheetThisWeeksWorkTableWrapper_Query(
		$pageSize: Int
		$cursor: String
		$taskTypeSearchQuery: TaskSearchQueryType
		$sortValue: String
		$timePeriod: String
		$currentViewingDate: String
		$personId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "timesheet_this_weeks_work_table_wrapper")
			...TimesheetThisWeeksWorkTableWrapper_viewer
				@arguments(
					pageSize: $pageSize
					cursor: $cursor
					taskTypeSearchQuery: $taskTypeSearchQuery
					sortValue: $sortValue
					timePeriod: $timePeriod
					currentViewingDate: $currentViewingDate
					personId: $personId
				)
		}
	}
`;
export { TimesheetThisWeeksWorkTableWrapperQuery };
export default withSocketHandling(createPaginationContainer(TimesheetThisWeeksWorkTableWrapper, {
    viewer: graphql `
				fragment TimesheetThisWeeksWorkTableWrapper_viewer on Viewer
				@argumentDefinitions(
					pageSize: {type: "Int"}
					cursor: {type: "String"}
					taskTypeSearchQuery: {type: "TaskSearchQueryType"}
					sortValue: {type: "String"}
					timePeriod: {type: "String"}
					currentViewingDate: {type: "String"}
					personId: {type: "ID!"}
				) {
					id
					actualPersonId
					harvestUser
					unit4User
					company {
						id
						bambooHREnabled
						harvestEnabled
						unit4Enabled
						useTimeOffApproval
						roles(includeDisabled: false) {
							edges {
								node {
									id
									name
								}
							}
						}
						taskTypeQueryTasks: allTasks(
							first: $pageSize
							after: $cursor
							searchQuery: $taskTypeSearchQuery
							sortValue: $sortValue
							approvedOnly: true
							runningProjectOnly: true
							simpleResponse: true
						) @connection(key: "Company_taskTypeQueryTasks", filters: []) {
							pageInfo {
								endCursor
								hasNextPage
							}
							edges {
								node {
									id
									userCanDeleteTask
									userCantDeleteTaskReason
									name
									latestUiUpdateAt
									startDay
									startMonth
									startYear
									deadlineYear
									deadlineMonth
									deadlineDay
									timeLeft
									estimateForecastMinutes
									totalMinutesRegistered
									companyTaskId
									favoured
									highPriority
									bug
									blocked
									approved
									canStart
									canBeSetToDone
									billable
									fullAccessToProject
									parentTaskId
									parentTask {
										name
										companyTaskId
									}
									taskType
									statusColumnV2 {
										id
										name
										category
									}
									assignedPersons {
										id
										firstName
										lastName
										email
										profilePictureId
										profilePictureDefaultId
										permissions
									}
									project {
										id
										name
										companyProjectId
										minutesPerEstimationPoint
										estimationUnit
										projectColor
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
										taskLevels
										status
										fullAccessToProject
										projectPerson(personId: $personId) {
											id
											role {
												id
												name
											}
										}
										harvestProject {
											id
										}
										unit4Project {
											id
											activitiesEnabled
										}
										client {
											id
											name
										}
										# Project Indicator - not fragment because of JS/TS incompatibilty
										name
										companyProjectId
										customProjectId
										projectColor
									}
									phase {
										id
										name
									}
								}
								cursor
							}
						}
					}
				}
			`,
}, {
    direction: 'forward',
    getConnectionFromProps({ viewer }) {
        var _a;
        return (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.taskTypeQueryTasks;
    },
    getFragmentVariables(prevVars, totalCount) {
        return Object.assign(Object.assign({}, prevVars), { pageSize: totalCount });
    },
    getVariables(_, { count, cursor }, fragmentVariables) {
        return Object.assign(Object.assign({}, fragmentVariables), { pageSize: count, cursor });
    },
    query: graphql `
				query TimesheetThisWeeksWorkTableWrapperRefetchQuery(
					$pageSize: Int
					$cursor: String
					$taskTypeSearchQuery: TaskSearchQueryType
					$sortValue: String
					$timePeriod: String
					$currentViewingDate: String
					$personId: ID!
				) {
					viewer {
						component(name: "timesheet_this_weeks_work_table_wrapper")
						...TimesheetThisWeeksWorkTableWrapper_viewer
							@arguments(
								pageSize: $pageSize
								cursor: $cursor
								taskTypeSearchQuery: $taskTypeSearchQuery
								sortValue: $sortValue
								timePeriod: $timePeriod
								currentViewingDate: $currentViewingDate
								personId: $personId
							)
					}
				}
			`,
}));
