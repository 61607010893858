import React, { useMemo, useRef, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import { Button, FlexColumn, FlexRow, Heading, SearchField, Tabs } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import { getNotNullishNodes } from '../../shared/util/NotNullPredicate';
import { createReport } from './actions/CreateReport';
import Util from '../../shared/util/util';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import ReportsPageChangeViewButton from './ReportsPageChangeViewButton';
import { mapReportToRow } from './ReportRowMapper';
import ReportsTabContent from './ReportsTabContent';
import { hasPermission } from '../../shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
const GROUP_BY_LOCALSTORAGE_KEY = 'report-page-group-by';
const Toolbar = ({ children }) => React.createElement(FlexRow, null, children);
Toolbar.Left = ({ children }) => React.createElement(FlexRow, { gap: 's' }, children);
Toolbar.Right = ({ children }) => (React.createElement(FlexRow, { gap: 's', justifyContent: 'end' }, children));
const PageWrapper = Styled.div `
  padding: 32px;
`;
const ReportsPageTabs = {
    my: 'my',
    company: 'company',
    all: 'all',
};
const ReportsPage = ({ viewer }) => {
    var _a;
    const intl = useIntl();
    const history = useHistory();
    const { formatMessage } = intl;
    const scrollRef = useRef(null);
    const [search, setSearch] = useState('');
    const [tab, setTab] = useState('company');
    const [groupings, setGroupings] = useState(() => JSON.parse(Util.localStorageGetItem(GROUP_BY_LOCALSTORAGE_KEY) || JSON.stringify([])));
    const groupingColumnHeader = groupings.length
        ? formatMessage({ id: 'report.grouping' }) +
            ': ' +
            groupings.map(grouping => formatMessage({ id: 'report.column.' + grouping })).join(' / ')
        : 'Name';
    const onGroupingsChange = (groupings) => {
        Util.localStorageSetItem(GROUP_BY_LOCALSTORAGE_KEY, JSON.stringify(groupings));
        setGroupings(groupings);
        const groupingObj = groupings.reduce((result, item, index) => {
            result[`level_${index + 1}`] = item;
            return result;
        }, {});
        trackEvent('Group By', 'Changed', Object.assign({ groupBy: groupings }, groupingObj));
    };
    const allReports = useMemo(() => {
        var _a, _b;
        return getNotNullishNodes((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.allSavedReports) === null || _b === void 0 ? void 0 : _b.edges)
            .filter(report => report.groupingFour !== 'converted')
            .map(report => mapReportToRow(report, viewer.actualPersonId));
    }, [(_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.allSavedReports]);
    const myReports = useMemo(() => allReports.filter(report => report.isReportOwner), [allReports]);
    const companyReports = useMemo(() => allReports.filter(report => !report.isReportOwner && !report.privateAccess), [allReports]);
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    return (React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
            scrollRef.current = node;
        } },
        React.createElement(PageWrapper, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(Toolbar, null,
                    React.createElement(Toolbar.Left, null,
                        React.createElement(Heading, { size: '3' },
                            React.createElement(FormattedMessage, { id: "header.reports" }))),
                    React.createElement(Toolbar.Right, null,
                        React.createElement(SearchField, { value: search, onChange: setSearch }),
                        React.createElement(ReportsPageChangeViewButton, { groupings: groupings, onGroupingsChange: onGroupingsChange }),
                        React.createElement(Button, { onClick: () => createReport(history) },
                            React.createElement(FormattedMessage, { id: "reports.create-report" })))),
                React.createElement(Tabs, { value: tab, onValueChange: setTab },
                    React.createElement(Tabs.List, null,
                        React.createElement(Tabs.Tab, { value: ReportsPageTabs.my }, `My Reports (${myReports.length})`),
                        React.createElement(Tabs.Tab, { value: ReportsPageTabs.company }, `Company Reports (${companyReports.length})`),
                        isAdmin && React.createElement(Tabs.Tab, { value: ReportsPageTabs.all }, `All Reports (${allReports.length})`)),
                    React.createElement(Tabs.Content, { value: ReportsPageTabs.my },
                        React.createElement(ReportsTabContent, { company: viewer.company, reports: myReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader })),
                    React.createElement(Tabs.Content, { value: ReportsPageTabs.company },
                        React.createElement(ReportsTabContent, { company: viewer.company, reports: companyReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader })),
                    isAdmin && (React.createElement(Tabs.Content, { value: ReportsPageTabs.all },
                        React.createElement(ReportsTabContent, { company: viewer.company, reports: allReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader }))))))));
};
export default createRefetchContainer(ReportsPage, {
    viewer: graphql `
			fragment ReportsPage_viewer on Viewer {
				id
				actualPersonId
				availableFeatureFlags {
					key
				}
				company {
					...ReportsTable_company
					id
					modules {
						moduleType
					}
					allSavedReports(first: 100000) @connection(key: "Company_allSavedReports") {
						edges {
							node {
								id
								name
								type
								person {
									id
									fullName
									email
									profilePictureId
								}
								groupingFour
								reportService
								shares(first: 100) @connection(key: "Report_shares", filters: [""]) {
									edges {
										node {
											id
											user {
												id
												email
											}
										}
									}
								}
								updatedAt
								privateAccess
								favorite
							}
						}
					}
				}
			}
		`,
}, graphql `
		query ReportsPageRefetchQuery {
			viewer {
				component(name: "reports_page")
				...ReportsPage_viewer
			}
		}
	`);
