import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import HeaderBar from '../../shared/components/headers/header-bar/header_bar';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_FORMAT_DAY,
	ELEMENT_TYPE,
	GLOBAL_FILTER_FIELD,
	GLOBAL_FILTER_OPERATOR,
	HIDDEN_FEATURES,
	PROGRAM_BUDGET_TYPE,
} from '../../../constants';
import {getHolidaysOfTheDay, handlePersonSelected, workingHourForTheDay} from './timesheets_person_data';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import TimesheetsTopSection from './top-section/timesheets_top_section';
import TimesheetsBottomSection from './bottom-section/TimesheetsBottomSection';
import DeprecatedTimesheetsBottomSection from './bottom-section/deprecated/timesheets_bottom_section';
import {HeaderPageContainer, MyTimesheetWrapper, PageWrapper} from './timesheets_styled';
import {
	handleAdjacentPeriodClick,
	handleDateRangeClick,
	handleTodayButtonClick,
	TIME_PERIOD,
} from './timesheets_change_date_view';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {getSocketConfig} from './timesheet_socket_handling';
import useEventListener from '../../shared/hooks/useEventListener';
import * as tracking from '../../../tracking';
import Util from '../../shared/util/util';
import {
	hasFeatureFlag,
	usingInternalTimeApproval,
	usingTimeApproval,
	usingTimeOffApproval,
} from '../../shared/util/FeatureUtil';
import {getCompanyLockedDate, getLockedDate} from '../../../components/timesheets/time-lock/TimeLockUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {hasPermission} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasTopDownProgramBudgetFeature} from '../../shared/util/ProgramFinancialLogic';
import {useForecastFetchQuery} from '../../shared/hooks/useForecastFetchQuery';
import {TimeRegistrationTargetSuggestionsQuery} from './time-registration-target-suggestions/TimeRegistrationTargetSuggestionsFetch';
import {isBillableSplitAllowed, isInternalTimeRegistrationAllowed} from '../../shared/util/cache/TimeRegistrationSettingsUtil';
import {isDateDisabled} from '../../shared/util/DateUtil';
import {Scrollbar} from '@forecast-it/design-system';
import CompanySetupUtil from '../../shared/util/CompanySetupUtil';
import {useCalendarEvents} from './top-section/calendar-events/useCalendarEvents';
import {USER_PILOT_FLOW_TIMESHEETS_REMASTER} from '../../shared/util/UserPilotConstants';
import {getTaskUrl, pathIncludesTask} from '../../shared/util/UrlUtil';

// FIXME: Remove this component after rollout of timesheet_remaster FF
const FeatureFlaggedScrollbar = ({children, handleScroll}) => {
	if (hasFeatureFlag('timesheet_remaster')) {
		return (
			<Scrollbar onScroll={handleScroll} isPageScroller>
				{children}
			</Scrollbar>
		);
	}

	return (
		<CustomScrollDiv className="custom-scrollbar-div" handleScroll={handleScroll}>
			{children}
		</CustomScrollDiv>
	);
};

const pageSize = 15;

export const taskTypeDefaultOptions = {
	suggested: {
		id: 'suggested-switch',
		checked: false,
		messageId: 'task_modal.suggested',
		infoMessageId: 'timesheets.suggested_tasks.hover_explanation',
		icon: 'ai-icon',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.suggested',
		cy: '-suggested',
	},
	starred: {
		id: 'starred-switch',
		checked: false,
		messageId: 'my_tasks.starred',
		val: true,
		infoMessageId: 'timesheets.starred_tasks.hover_explanation',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.starred',
		cy: '-starred',
	},
	recent: {
		id: 'recent-switch',
		checked: false,
		messageId: 'timesheets.recent',
		infoMessageId: 'timesheets.recent_time_registration.hover_explanation',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.recent',
		cy: '-recent',
	},
	planned: {
		id: 'planned-switch',
		checked: false,
		messageId: 'expenses.planned',
		infoMessageId: 'timesheets.planned.hover_explanation',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.planned',
		cy: '-planned',
	},
	inProgress: {
		id: 'inprogress-switch',
		checked: false,
		messageId: 'common.in_progress',
		val: 'INPROGRESS',
		infoMessageId: 'timesheets.in_progress.hover_explanation',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.in_progress',
		cy: '-inprogress',
	},
	assignedToMe: {
		id: 'assignedtome-switch',
		checked: false,
		messageId: 'common.assigned_to_me',
		val: 'ASSIGNEDTOME',
		infoMessageId: 'timesheets.assigned_to_me.hover_explanation',
		nothingReturnedMessageId: 'switch_group.nothing_returned_message.assigned_to_me',
		cy: '-assignedtome',
	},
};

const getInitialCalendarState = () => {
	if (hasFeatureFlag('timesheet_remaster')) {
		return localStorage.getItem('timesheet-v2-show-calendar')
			? JSON.parse(localStorage.getItem('timesheet-v2-show-calendar'))
			: false;
	}

	return localStorage.getItem('timesheet-show-calendar') ? JSON.parse(localStorage.getItem('timesheet-show-calendar')) : true;
};

const timesheetsPage = ({intl, viewer, relay, history, children, setSocketConfig, setSocketVariables}) => {
	if (!viewer.company) return <div></div>;
	if (!Util.AuthorizeViewerAccess('timesheets')) {
		const path = history.location.pathname;
		if (pathIncludesTask(path)) {
			history.push(getTaskUrl(path));
		} else {
			// if user doesnt have access rights to view this page redirect to no access page
			history.push('/not-authorized');
		}
		return <div></div>;
	}
	if (Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
		history.push('/');
	}

	const {formatMessage} = intl;
	const hasTimeRegistrationCreateAllPermission = hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_CREATE_ALL);

	const customFieldDefinitions = viewer.company.customFieldDefinitions?.edges.map(edge => edge.node);

	const hasTimesheetRemaster = hasFeatureFlag('timesheet_remaster');
	const selectedSwitchLocalStorageKey = hasFeatureFlag('timereg_show_new_task_suggestions')
		? 'timesheets-selected-switch-v2'
		: 'timesheets-selected-switch';

	const {fetch: fetchTimeRegistrationTargetSuggestions, data: timeRegistrationTargetSuggestionsData} = useForecastFetchQuery(
		TimeRegistrationTargetSuggestionsQuery
	);

	const [showCalendar, setShowCalendar] = useState(getInitialCalendarState());
	const [currentDayOfWeek, setCurrentDayOfWeek] = useState(Moment().weekday());
	const [currentViewingDate, setCurrentViewingDate] = useState(() => Moment().startOf('day'));
	const [currentViewingMonth, setCurrentViewingMonth] = useState(Moment());
	const [selectedPersonId, setSelectedPersonId] = useState(viewer.actualPersonId);
	const [selectedSwitch, setSelectedSwitch] = useState(localStorage.getItem(selectedSwitchLocalStorageKey) || 'suggested');
	const [timePeriod, setTimePeriod] = useState(localStorage.getItem('timesheet-time-period') || TIME_PERIOD.WEEK_VIEW);
	const [weekArray, setWeekArray] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [googleTask, setGoogleTask] = useState(viewer.task);

	const [showTimeEntryPortal, setShowTimeEntryPortal] = useState(false);
	const [hideModal, setHideModal] = useState(window.innerWidth < 1240);

	const paginationRef = useRef(null);

	const lockedDate = getLockedDate(viewer.company, viewer.company.person);
	const companyLockedDate = useMemo(() => getCompanyLockedDate(viewer.company), []);

	const selectedPerson = viewer.company.person;
	const nonViewerPersonSelected = viewer.actualPersonId !== selectedPersonId;

	const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;

	const currentViewingDateMemo = useMemo(() => currentViewingDate.clone(), [currentViewingDate]);
	const lastWorkingDayInWeek = useMemo(() => {
		if (timePeriod !== TIME_PERIOD.WEEK_VIEW || weekArray.length === 0) return currentViewingDate;
		// Iterate through array backwards. This way, the first working day found will be the last working day of the week.
		return weekArray
			.slice()
			.reverse()
			.find(day => {
				const workingHours = workingHourForTheDay(selectedPerson, day);
				const holidaysForDay = getHolidaysOfTheDay(selectedPerson, day);
				// A working day has more than 0 workingHours and exactly 0 holidays
				return workingHours !== 0 && holidaysForDay.length === 0;
			});
	}, [weekArray, selectedPerson]);

	const isFullyLocked = useMemo(() => {
		if (lockedDate) {
			if (isWeekView) {
				const lastValidDateInWeek = CompanySetupUtil.weekendsAlwaysHidden()
					? lastWorkingDayInWeek
					: currentViewingDate.clone().endOf('week');
				return lockedDate.isSameOrAfter(lastValidDateInWeek, 'd');
			}

			return lockedDate.isSameOrAfter(currentViewingDate, 'd');
		}
		return false;
	}, [isWeekView, lockedDate, currentViewingDate, lastWorkingDayInWeek]);

	const showCalendarEvents =
		hasFeatureFlag('google_calendar_integration') &&
		!!viewer.calendarUser &&
		!!viewer.enabledCalendars?.length &&
		!viewer.calendarTokenError &&
		!nonViewerPersonSelected &&
		!!currentViewingDateMemo;
	const {calendarEvents, isLoadingCalendarEvents} = useCalendarEvents(
		currentViewingDateMemo.clone(),
		currentViewingMonth.clone(),
		showCalendarEvents
	);

	const refetch = (variables, renderVariables, callback, options) => {
		relay.refetch(variables, renderVariables, callback, options);
		setSocketVariables(variables);
	};

	const handlePersonChange = person => {
		tracking.trackPageAction('Person Changed');
		trackEvent('Person', 'Changed', {
			selectedPerson: person.value ? (person.value === viewer.actualPersonId ? 'myself' : person.email) : 'myself',
		});
		setSelectedPersonId(handlePersonSelected(person, currentViewingMonth, refetch));
	};

	const handleResize = useCallback(() => {
		setHideModal(window.innerWidth < 1240);
		setShowTimeEntryPortal(false);
	}, [window.innerWidth]);

	const handleSelectedSwitch = val => {
		trackEvent('Lower Timesheets Switch', 'Selected', {switch: val});
		setSelectedSwitch(val);
	};

	const dateChangeCallback = () => {
		setLoading(false);
	};

	//Date picker
	const handleDateRange = (calendarShown, date, passedPersonId) => {
		setLoading(true);
		const newDate = handleDateRangeClick(
			date,
			calendarShown ? TIME_PERIOD.MONTH_VIEW : timePeriod,
			passedPersonId || selectedPersonId,
			refetch,
			dateChangeCallback
		);
		setCurrentViewingDate(newDate);
		setCurrentViewingMonth(newDate);
	};

	useEventListener('resize', handleResize);

	useTrackPage('My Timesheet');

	useEffect(() => {
		document.title = 'Timesheets - Forecast';
		const taskIdFromUrl = Util.getUrlQueryParameter('showTaskId');
		if (taskIdFromUrl) {
			relay.refetch({googleTaskId: taskIdFromUrl});
		}
		tracking.trackPage('Timesheet-page', null, null, taskIdFromUrl ? {calendarIntegration: true} : null);

		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		const actualPersonIdInt = parseInt(atob(viewer.actualPersonId).replace('Person:', ''));
		setSocketConfig(getSocketConfig([actualPersonIdInt, selectedPersonIdInt]), relay.refetch);
	}, []);

	useEffect(() => {
		const dateFromURL = Util.getUrlQueryParameter('date');
		if (dateFromURL) {
			const splitDate = dateFromURL.split('-');
			if (splitDate.length === 3) {
				const date = dateFromURL ? Util.CreateNonUtcMomentDate(splitDate[0], splitDate[1], splitDate[2]) : null;
				const personIdFromURL = Util.getUrlQueryParameter('personId');
				if (date && personIdFromURL) {
					setTimePeriod(TIME_PERIOD.WEEK_VIEW);
					if (hasTimeRegistrationCreateAllPermission) {
						setSelectedPersonId(personIdFromURL);
						handleDateRange(true, date, personIdFromURL);
					} else {
						handleDateRange(true, date);
					}
				}
			}
		}
	}, []);

	useEffect(() => {
		if (hasFeatureFlag('timereg_target_suggestions_shadowing') || hasFeatureFlag('timesheet_remaster')) {
			fetchTimeRegistrationTargetSuggestions({
				timePeriod,
				currentViewingDate: currentViewingDate.format('YYYY-MM-DD'),
			});
		}
	}, [currentViewingDateMemo.format('YYYYMMDD'), timePeriod]);

	useEffect(() => {
		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		setSocketConfig(getSocketConfig(selectedPersonIdInt));
		// When the person is changed, the switch should be null UNLESS the person is changed back to the actual person
		setSelectedSwitch(
			viewer.actualPersonId === selectedPersonId
				? localStorage.getItem(selectedSwitchLocalStorageKey) || 'suggested'
				: null
		);
	}, [selectedPersonId]);

	useEffect(() => {
		setGoogleTask(viewer.task);
	}, [viewer.task]);

	const toggleCalendar = () => {
		if (hasTimesheetRemaster) {
			Util.localStorageSetItem('timesheet-v2-show-calendar', !showCalendar);
		} else {
			Util.localStorageSetItem('timesheet-show-calendar', !showCalendar);
		}
		setShowCalendar(!showCalendar);
		setShowTimeEntryPortal(false);
		trackEvent('Calender Toggle', 'Clicked', {showCalender: !showCalendar, hasTimesheetRemaster});
	};

	//Today Button
	const goToToday = () => {
		setLoading(true);
		setCurrentViewingDate(handleTodayButtonClick(selectedPersonId, refetch, dateChangeCallback));
		setCurrentViewingMonth(Moment());
	};

	//Next/Previous date range buttons
	const adjacentPeriod = (calendarShown, forward) => {
		//if the day selected is a day outside of the month selected we don't want to jump to the next/previous month but to the month oof the day selected
		const keepMonth = calendarShown && currentViewingDate.month() !== currentViewingMonth.month() ? 0 : 1;
		setLoading(true);
		const newDate = handleAdjacentPeriodClick(
			currentViewingDate,
			forward,
			calendarShown ? TIME_PERIOD.MONTH_VIEW : timePeriod,
			selectedPersonId,
			refetch,
			dateChangeCallback,
			keepMonth
		);
		setCurrentViewingDate(newDate);
		setCurrentViewingMonth(newDate);
	};

	//Timesheet Month calendar (not the date picker)
	const selectDayFromCalendar = useCallback(
		(day, isWeek) => {
			//TODO improve this to reduce the number of setState
			setTimePeriod(isWeek ? TIME_PERIOD.WEEK_VIEW : TIME_PERIOD.DAY_VIEW);
			const todayIsInWeek = day.clone().week() === Moment().week();

			// If week selected, set CurrentViewingDate to today if today is in the selected week, or the same weekday as what was selected before if not.
			setCurrentViewingDate(
				isWeek
					? todayIsInWeek
						? Moment()
						: day
								.clone()
								//.startOf('week')
								.weekday(currentDayOfWeek)
					: day
			);
		},
		[currentViewingMonth, currentDayOfWeek]
	);

	//Switch between Day and Week
	const setTimePeriodWithDate = period => {
		setTimePeriod(period);
	};

	const buildWeekArr = (currentDate, limit) => {
		const weekArray = [];
		const rec = (date, acc, limit) => {
			if (limit > 0) {
				acc.push(date);
				rec(date.clone().add(1, 'days'), acc, limit - 1);
			}
			return acc;
		};
		return rec(currentDate.clone().startOf('week'), weekArray, limit);
	};

	useEffect(() => {
		Util.localStorageSetItem('timesheet-time-period', timePeriod);
	}, [timePeriod]);

	useEffect(() => {
		if (timePeriod === TIME_PERIOD.DAY_VIEW) {
			setCurrentDayOfWeek(currentViewingDate.weekday());
		}

		// In order to avoid building weekArray on every render, it is stored in state and only rebuilt when currentViewingDate or timePeriod changes.
		const tempWeekArray = timePeriod === TIME_PERIOD.WEEK_VIEW ? buildWeekArr(currentViewingDate, 7) : [];
		if (tempWeekArray !== weekArray) {
			setWeekArray(tempWeekArray);
		}
	}, [currentViewingDate, timePeriod]);

	const onCopyFromLastWeekClicked = () => {
		const copyFullWeek = timePeriod === TIME_PERIOD.WEEK_VIEW;
		if (copyFullWeek) {
			trackEvent('Copy Previous Week Button', 'Clicked');
		} else {
			trackEvent('Copy Previous Day Button', 'Clicked');
		}
		showModal({
			type: MODAL_TYPE.COPY_FROM_LAST_WEEK,
			personId: selectedPersonId,
			selectedDate: currentViewingDate.toDate(),
			copyFullWeek: timePeriod === TIME_PERIOD.WEEK_VIEW,
		});
	};

	const getHeaderTitle = () => {
		const hasTimesheetRemaster = hasFeatureFlag('timesheet_remaster');
		const hasNewTimeRegModal = hasFeatureFlag('new_time_registration_modal');
		const showInternalTimeHelp = isInternalTimeRegistrationAllowed() && !hasNewTimeRegModal;
		const showTimeOffHelp = hasNewTimeRegModal && !viewer.company.bambooHREnabled;
		const showingViewer = selectedPersonId === viewer.actualPersonId;
		const content = [];
		const onboardingFlows = [];

		if (hasTimesheetRemaster) {
			onboardingFlows.push({
				id: 'timesheets-new-improvements',
				title: 'New improvements to this page',
				description: null,
				contentId: 'agBtkGnlww',
			});

			onboardingFlows.push({
				id: 'timesheets-introduction',
				title: 'Introduction to this page',
				description: null,
				contentId: '-y0nXpeCts',
			});
		}

		if (showInternalTimeHelp) {
			onboardingFlows.push({
				id: 'timesheets-register-internal-time',
				title: 'How to register internal time',
				description: null,
				contentId: 'emSxYLzJaa',
			});
		}

		if (showTimeOffHelp) {
			onboardingFlows.push({
				id: 'timesheets-register-time-off',
				title: 'How to register time off',
				description: null,
				contentId: 'ju94wUwr7Y',
			});
		}

		if (hasTimeRegistrationCreateAllPermission) {
			onboardingFlows.push({
				id: 'timesheets-team-time',
				title: "Seeing your team's time registrations",
				contentId: '1681984211mAcq3994',
			});
		}

		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: formatMessage({id: 'my_timesheets.onboarding_title'}),
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4461863590289-Managing-Time',
			subLink:
				'https://support.forecast.app/hc/en-us/articles/4611517711505-Viewing-and-managing-your-personal-timesheet',
		};

		content.push(onboardingComponent);

		if (hasFeatureFlag('timesheet_remaster')) {
			const feedbackComponent = {
				id: 'feedback-component',
				type: TopHeaderBar.TYPE.FEEDBACK,
				link: 'https://www.forecast.app/feedback-timesheets',
			};
			content.push(feedbackComponent);
		}

		if (hasFeatureFlag('timesheet_remaster_opt_in')) {
			content.push({
				type: TopHeaderBar.TYPE.FEATURE_FLAG_OPT_BUTTON,
				featureFlag: 'timesheet_remaster',
				userPilotContentId: USER_PILOT_FLOW_TIMESHEETS_REMASTER,
			});
		}

		return (
			<TopHeaderBarWrapper className="top-header-bar">
				<TopHeaderBar
					title={showingViewer ? intl.formatMessage({id: 'timesheet.my_timesheet'}) : 'Team Member Timesheet'}
					content={content}
				/>
			</TopHeaderBarWrapper>
		);
	};

	// The CVS export date - taken from the current selected month
	const csvStartDate = Moment([currentViewingMonth.year(), currentViewingMonth.month()]);
	const csvEndDate = currentViewingMonth.clone().endOf('month');

	// The CVS search query values
	const csvSearchQuery = {
		filters: [
			{
				field: GLOBAL_FILTER_FIELD.WITH_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.IS,
				value: true.toString(),
			},
			{
				field: GLOBAL_FILTER_FIELD.START_DATE_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
				value: csvStartDate.format(DATE_FORMAT_DAY),
			},
			{
				field: GLOBAL_FILTER_FIELD.END_DATE_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
				value: csvEndDate.format(DATE_FORMAT_DAY),
			},
			{
				field: GLOBAL_FILTER_FIELD.PERSON_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.IS,
				value: [selectedPersonId],
			},
		],
	};

	const handleCsvDownload = () => {
		trackEvent('Download CSV Button', 'Clicked');
		showModal({
			type: MODAL_TYPE.REPORTED_TIME_DATE_CSV,
			searchQuery: csvSearchQuery,
			enabledColumns: {
				project: true,
				date: true,
				reportedTime: true,
				billableTime: isBillableSplitAllowed(),
				task: true,
				notes: true,
			},
			legacyDownload: true,
		});
	};

	const handleScroll = e => {
		if (paginationRef.current) {
			if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 300) {
				paginationRef.current.loadMore();
			}
		}
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];
		if (hasTimeRegistrationCreateAllPermission) {
			const personDropdown = {
				type: ELEMENT_TYPE.DROPDOWN_PERSON,
				customWidth: 172,
				selectedPerson: selectedPersonId,
				callback: handlePersonChange,
				cy: 'timesheets-select-person',
				userpilot: 'timesheets-select-person',
			};
			leftContent.push(personDropdown);
		}

		const dateChanger = {
			type: ELEMENT_TYPE.DATE_CHANGER_V2,
			useTodayButton: true,
			timePeriod: showCalendar ? TIME_PERIOD.MONTH_VIEW : timePeriod,
			id: 'time-date-select',
			currentViewingDate: showCalendar ? currentViewingMonth : currentViewingDate,
			handleTodayButtonClick: goToToday,
			handleAdjacentDateButtonClick: adjacentPeriod.bind(this, showCalendar),
			updateDateRange: handleDateRange.bind(this, showCalendar),
			boldLongDayMonth: true,
			selectorTooltipEnabled: true,
			selectorTooltipProps: {
				autoPlace: true,
				grey: true,
				infoText: formatMessage({id: 'common.select_date'}),
			},
			tooltipEnabled: true,
			tooltipProps: {
				autoPlace: true,
				infoText: Moment().format('ddd, DD. MMM') + ' ' + Moment().format('YYYY'),
				grey: true,
			},
			userpilot: 'timesheets-select-month',
		};

		leftContent.push(dateChanger);

		const periodToggle = {
			type: ELEMENT_TYPE.UNIT_TOGGLE,
			options: [
				{
					id: 'day',
					text: formatMessage({id: 'overview_time.day'}),
					onClick: () => {
						setTimePeriodWithDate(TIME_PERIOD.DAY_VIEW);
						trackEvent('Period Selector', 'Toggled', {timePeriod: TIME_PERIOD.DAY_VIEW});
					},
					selected: timePeriod === TIME_PERIOD.DAY_VIEW,
					dataCy: 'day',
				},
				{
					id: 'week',
					text: formatMessage({id: 'overview_time.week'}),
					onClick: () => {
						setTimePeriodWithDate(TIME_PERIOD.WEEK_VIEW);
						trackEvent('Period Selector', 'Toggled', {timePeriod: TIME_PERIOD.WEEK_VIEW});
					},
					selected: timePeriod === TIME_PERIOD.WEEK_VIEW,
					dataCy: 'week',
				},
			],
		};

		leftContent.push(periodToggle);

		// CSV Download
		rightContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownload,
		});

		const hideShow = {
			type: ELEMENT_TYPE.HIDE_AND_SHOW,
			cy: 'calendar-toggle',
			userpilot: 'timesheets-toggle-calender',
			showId: 'show-calendar',
			hideId: 'hide-calendar',
			hideText: formatMessage({id: 'timesheet.hide_calendar'}),
			showText: formatMessage({id: 'timesheet.show_calendar'}),
			isShowing: !showCalendar,
			callback: toggleCalendar,
		};
		rightContent.push(hideShow);

		const isCopyDisabled = isFullyLocked || isDateDisabled(currentViewingDate);
		const lockedTooltip = isCopyDisabled
			? {
					infoText:
						timePeriod === TIME_PERIOD.WEEK_VIEW
							? 'The week is locked for new time registrations'
							: 'The day is locked for new time registrations',
					showDelay: 300,
					tooltipPosition: 'bottom',
					canBeMiddle: true,
			  }
			: null;
		// Copy from last week button
		const copyFromLastWeek = {
			id: 'copy-from-last-week',
			type: ELEMENT_TYPE.BUTTON,
			text:
				timePeriod === TIME_PERIOD.WEEK_VIEW
					? formatMessage({id: 'timesheets.copy_previous_week'})
					: formatMessage({id: 'timesheets.copy_previous_day'}),
			style: BUTTON_STYLE.OUTLINE_THICK,
			color: BUTTON_COLOR.VERYLIGHTGREY,
			disabled: isCopyDisabled,
			tooltipProps: lockedTooltip,
			callback: () => {
				onCopyFromLastWeekClicked();
			},
			dataCy: 'copy-from-last-week-button',
		};

		rightContent.push(copyFromLastWeek);

		if (!showCalendar || hideModal || hasFeatureFlag('timesheet_remaster_new_calendar') || viewer.calendarFetchEnabled) {
			if (hasFeatureFlag('new_time_registration_modal')) {
				const addTime = {
					id: 'add-time-entry-button',
					type: ELEMENT_TYPE.BUTTON,
					text: formatMessage({id: 'common.new_time_entry_popup_title'}),
					callback: () => {
						showModal({
							type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
							personId: selectedPerson?.id,
							initialDate: currentViewingDate.toDate(),
						});
					},
					style: showTimeEntryPortal ? BUTTON_STYLE.FILLED : BUTTON_STYLE.OUTLINE_THICK,
					color: BUTTON_COLOR.MODERN_BLUE,
					cy: 'new-ui-scoping-custom-button-container',
					dataCy: 'overview-time-add-time-reg',
				};
				rightContent.push(addTime);
			} else {
				const addTime = {
					id: 'add-time-entry-button',
					type: ELEMENT_TYPE.BUTTON,
					text: formatMessage({id: 'common.new_time_entry_popup_title'}),
					callback: () => {
						setShowTimeEntryPortal(!showTimeEntryPortal);
						trackEvent('New Time Entry Button', 'Clicked', {
							calenderShown: showCalendar,
							newTimeEntrySectionShown: showCalendar && !hideModal,
							timeEntryModalShown: !showTimeEntryPortal,
						});
					},
					style: showTimeEntryPortal ? BUTTON_STYLE.FILLED : BUTTON_STYLE.OUTLINE_THICK,
					color: BUTTON_COLOR.MODERN_BLUE,
					cy: 'new-ui-scoping-custom-button-container',
					dataCy: 'overview-time-add-time-reg',
				};
				rightContent.push(addTime);
			}
		}

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};

	const hasTopDownProgramBudgetFeatureFlag = hasTopDownProgramBudgetFeature();
	const processedCompanyProjectIds = [];
	const overBudgetProgramsByCompanyProjectId = viewer.timeRegistrations.edges
		.filter(timeRegistration => {
			const project = timeRegistration.node.task?.project
				? timeRegistration.node.task?.project
				: timeRegistration.node.project;
			const alreadyProcessed = processedCompanyProjectIds.includes(project?.companyProjectId);
			if (!alreadyProcessed && project?.companyProjectId) processedCompanyProjectIds.push(project?.companyProjectId);
			return (
				hasTopDownProgramBudgetFeatureFlag &&
				!alreadyProcessed &&
				project &&
				project.isProgramRevenueLocked &&
				project.billable &&
				project.programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED
			);
		})
		.map(timeRegistration => {
			const project = timeRegistration.node.task?.project
				? timeRegistration.node.task?.project
				: timeRegistration.node.project;
			return {
				companyProjectId: project.companyProjectId,
				programInfo: project.program,
			};
		});

	return (
		<PageWrapper data-cy={'timesheets-page'} overflowHidden={hasTimesheetRemaster}>
			{children}
			<HeaderPageContainer>{getHeaderTitle()}</HeaderPageContainer>
			<HeaderPageContainer>{getHeader()}</HeaderPageContainer>
			<FeatureFlaggedScrollbar handleScroll={handleScroll}>
				<MyTimesheetWrapper>
					<TimesheetsTopSection
						viewer={viewer}
						refetch={refetch}
						pageSize={pageSize}
						hideModal={hideModal}
						showCalendar={showCalendar}
						currentViewingDate={currentViewingDateMemo}
						currentViewingMonth={currentViewingMonth}
						weekArray={weekArray}
						timePeriod={timePeriod}
						selectDayFromCalendar={selectDayFromCalendar}
						selectedPerson={selectedPerson}
						showTimeEntryPortal={showTimeEntryPortal}
						setShowTimeEntryPortal={setShowTimeEntryPortal}
						calenderLoading={isLoading}
						googleTask={googleTask}
						lockedDate={lockedDate}
						hasOpportunityAccess={Util.hasOpportunityAccess(viewer.company.modules)}
						overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
						timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData?.viewer}
						calendarEvents={calendarEvents}
						isLoadingCalendarEvents={isLoadingCalendarEvents}
					/>
					{hasFeatureFlag('timesheet_remaster') ? (
						<TimesheetsBottomSection
							paginationRef={paginationRef}
							currentViewingDate={currentViewingDateMemo}
							lastWorkingDayInWeek={lastWorkingDayInWeek}
							timeRegistrations={viewer.timeRegistrations.edges}
							timePeriod={timePeriod}
							nonViewerPersonSelected={nonViewerPersonSelected}
							isFullyLocked={isFullyLocked}
							selectedPerson={selectedPerson}
							selectedSwitch={selectedSwitch}
							setSelectedSwitch={handleSelectedSwitch}
							selectDayFromCalendar={selectDayFromCalendar}
							lockedDate={lockedDate}
							companyLockedDate={companyLockedDate}
							availableFeatureFlags={viewer.availableFeatureFlags}
							usingTimeApproval={usingTimeApproval(viewer.company.useTimeApproval)}
							usingInternalTimeApproval={usingInternalTimeApproval(viewer.company.useInternalTimeApproval)}
							usingTimeOffApproval={usingTimeOffApproval(viewer.company.useTimeOffApproval)}
							customFieldDefinitions={customFieldDefinitions}
							overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
							timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData?.viewer}
							viewer={viewer}
						/>
					) : (
						<DeprecatedTimesheetsBottomSection
							currentViewingDate={currentViewingDateMemo}
							lastWorkingDayInWeek={lastWorkingDayInWeek}
							timeRegistrations={viewer.timeRegistrations.edges}
							timePeriod={timePeriod}
							groupLocked={nonViewerPersonSelected}
							selectedPerson={selectedPerson}
							showingViewer={selectedPerson.id === viewer.actualPersonId}
							selectedSwitch={selectedSwitch}
							setSelectedSwitch={handleSelectedSwitch}
							selectDayFromCalendar={selectDayFromCalendar}
							lockedDate={lockedDate}
							companyLockedDate={companyLockedDate}
							availableFeatureFlags={viewer.availableFeatureFlags}
							usingTimeApproval={usingTimeApproval(viewer.company.useTimeApproval)}
							usingInternalTimeApproval={usingInternalTimeApproval(viewer.company.useInternalTimeApproval)}
							usingTimeOffApproval={usingTimeOffApproval(viewer.company.useTimeOffApproval)}
							customFieldDefinitions={customFieldDefinitions}
							overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
							timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData?.viewer}
						/>
					)}
				</MyTimesheetWrapper>
			</FeatureFlaggedScrollbar>
		</PageWrapper>
	);
};

// RELAY SECTION

const timesheetsPageQuery = graphql`
	query timesheetsPage_Query(
		$personId: ID
		$googleTaskId: ID
		$pageSize: Int
		$startDateString: String
		$endDateString: String
		$fetchCustomFields: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "timesheet")
			...timesheetsPage_viewer
				@arguments(
					personId: $personId
					googleTaskId: $googleTaskId
					pageSize: $pageSize
					startDateString: $startDateString
					endDateString: $endDateString
					fetchCustomFields: $fetchCustomFields
				)
		}
	}
`;

export {timesheetsPageQuery};

export default withRouter(
	withSocketHandling(
		injectIntl(
			createRefetchContainer(
				timesheetsPage,
				{
					viewer: graphql`
						fragment timesheetsPage_viewer on Viewer
						@argumentDefinitions(
							personId: {type: "ID"}
							googleTaskId: {type: "ID"}
							pageSize: {type: "Int"}
							startDateString: {type: "String"}
							endDateString: {type: "String"}
							fetchCustomFields: {type: "Boolean!"}
						) {
							id
							harvestUser
							unit4User
							actualPersonId
							email
							backendId
							language
							availableFeatureFlags {
								key
							}
							filters(first: 1000000, filterSection: MY_TIMESHEETS)
								@connection(key: "Viewer_filters", filters: []) {
								edges {
									node {
										id
										name
										section
										value
										updatedAt
									}
								}
							}
							calendarUser {
								name
							}
							calendarTokenError
							calendarFetchEnabled
							enabledCalendars
							company {
								id
								useTimeApproval
								useInternalTimeApproval
								useTimeOffApproval
								harvestEnabled
								bambooHREnabled
								unit4Enabled
								characterLimit
								lockedPeriodYear
								lockedPeriodMonth
								lockedPeriodDay
								roles(includeDisabled: false) {
									edges {
										node {
											id
											name
										}
									}
								}
								modules {
									moduleType
								}
								idleTimes(first: 100000) {
									edges {
										node {
											id
											name
											isInternalTime
											favoured
											disabled
										}
									}
								}
								person(id: $personId) {
									holidayCalendar {
										id
										holidayCalendarEntries(first: 10000) {
											edges {
												node {
													id
													day
													month
													year
													name
												}
											}
										}
										name
									}
									id
									role {
										id
										name
									}
									firstName
									lastName
									fullName
									createdAt
									startDate
									endDate
									systemUser
									initials
									active
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									excludeFromCompanyLockedPeriod
									submitLockedDateYear
									submitLockedDateMonth
									submitLockedDateDay
									harvestUser
									unit4User
									economicUser
									profilePictureId
									profilePictureDefaultId
									email
								}
								customFieldDefinitions(first: 1000)
									@connection(key: "Company_customFieldDefinitions")
									@include(if: $fetchCustomFields) {
									edges {
										node {
											id
											key
											displayName
											entityType
											readOnly
										}
									}
								}
							}
							projects(first: 100000, excludeReadOnly: true, excludeRestricted: true) {
								edges {
									...ProjectDropdown_projects
									node {
										id
										name
										fullAccessToProject
										companyProjectId
										status
										billable
										projectColor
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
										manualProgressOnProjectEnabled
										manualProgressOnPhasesEnabled
										manualProgressOnTasksEnabled
										projectPerson(personId: $personId) {
											role {
												id
												name
											}
										}
										harvestProject {
											id
										}
										unit4Project {
											id
											activitiesEnabled
										}
										isJiraProject
									}
								}
							}
							timeRegistrations(
								first: 100000
								personId: $personId
								startDate: $startDateString
								endDate: $endDateString
								verifyPermissionRights: false
							) @connection(key: "Viewer_timeRegistrations", filters: []) {
								edges {
									node {
										id
										day
										month
										year
										minutesRegistered
										billableMinutesRegistered
										updatedAt
										role {
											id
											name
										}
										notes
										invoiced
										xeroInvoiceId
										lockedInPeriod
										harvestTimeId
										allocationId
										harvestTaskIdInt
										harvestError
										unit4ActivityId
										approvalStatus
										project {
											id
											name
											status
											projectColor
											companyProjectId
											estimationUnit
											fullAccessToProject
											isProgramRevenueLocked
											programBudgetType
											program {
												name
												prefix
												budgetType
												members {
													edges {
														node {
															role
															person {
																id
															}
														}
													}
												}
											}
											billable
											projectStartYear
											projectStartMonth
											projectStartDay
											projectEndYear
											projectEndMonth
											projectEndDay
											manualProgressOnProjectEnabled
											manualProgressOnPhasesEnabled
											manualProgressOnTasksEnabled
											projectPerson(personId: $personId) {
												role {
													id
													name
												}
											}
											harvestProject {
												id
												name
											}
											unit4Project {
												id
												name
												activitiesEnabled
											}
											client {
												id
												name
											}
											# Project Indicator - not fragment because of JS/TS incompatibilty
											name
											companyProjectId
											customProjectId
											projectColor
										}
										task {
											id
											name
											progress
											companyTaskId
											jiraId
											highPriority
											approved
											timeLeft
											favoured
											fullAccessToProject
											latestUiUpdateAt
											timeLeft
											estimateForecastMinutes
											totalMinutesRegistered
											billable
											parentTaskId
											parentTask {
												name
												companyTaskId
											}
											taskType
											startYear
											startMonth
											startDay
											deadlineYear
											deadlineMonth
											deadlineDay
											statusColumnV2 {
												category
											}
											assignedPersons {
												id
											}
											project {
												id
												name
												status
												companyProjectId
												isProgramRevenueLocked
												programBudgetType
												program {
													name
													prefix
													budgetType
													members {
														edges {
															node {
																role
																person {
																	id
																}
															}
														}
													}
												}
												billable
												projectColor
												estimationUnit
												fullAccessToProject
												projectStartYear
												projectStartMonth
												projectStartDay
												projectEndYear
												projectEndMonth
												projectEndDay
												manualProgressOnProjectEnabled
												manualProgressOnPhasesEnabled
												manualProgressOnTasksEnabled
												projectPerson(personId: $personId) {
													role {
														id
														name
													}
												}
												client {
													id
													name
												}
												harvestProject {
													id
													name
												}
												unit4Project {
													id
													name
													activitiesEnabled
												}
												# Project Indicator - not fragment because of JS/TS incompatibilty
												name
												companyProjectId
												customProjectId
												projectColor
											}
											phase {
												id
												name
											}
										}
										idleTime {
											id
											name
											isInternalTime
											favoured
											disabled
										}
										person {
											id
											firstName
											lastName
											isViewer
											role {
												id
												name
											}
										}
										customFieldValues(first: 1000, fetchCustomFields: $fetchCustomFields)
											@connection(key: "TimeRegistrations_customFieldValues") {
											edges {
												node {
													key
													displayName
													value
													readOnly
												}
											}
										}
									}
								}
							}
							task(id: $googleTaskId) {
								id
								name
								companyTaskId
								timeLeft
								estimateForecastMinutes
								totalMinutesRegistered
								fullAccessToProject
								phase {
									id
									name
								}
								parentTask {
									name
									companyTaskId
								}
								project {
									id
									name
									projectColor
									companyProjectId
									fullAccessToProject
									harvestProject {
										id
									}
									unit4Project {
										id
										activitiesEnabled
									}
									projectPerson(personId: $personId) {
										role {
											id
											name
										}
									}
									client {
										id
										name
									}
								}
								statusColumnV2 {
									id
									name
									category
								}
								assignedPersons {
									id
									fullName
									active
									role {
										id
										name
									}
								}
							}
						}
					`,
				},
				graphql`
					query timesheetsPageRefetchQuery(
						$personId: ID
						$googleTaskId: ID
						$pageSize: Int
						$startDateString: String
						$endDateString: String
						$fetchCustomFields: Boolean!
					) {
						viewer {
							component(name: "timesheet")
							...timesheetsPage_viewer
								@arguments(
									personId: $personId
									googleTaskId: $googleTaskId
									pageSize: $pageSize
									startDateString: $startDateString
									endDateString: $endDateString
									fetchCustomFields: $fetchCustomFields
								)
						}
					}
				`
			)
		)
	)
);
