/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ReportsPageRefetchQuery {
  viewer {
    component(name: "reports_page")
    ...ReportsPage_viewer
    id
  }
}

fragment ReportTable_company on Company {
  id
}

fragment ReportsPage_viewer on Viewer {
  id
  actualPersonId
  availableFeatureFlags {
    key
    id
  }
  company {
    ...ReportTable_company
    id
    modules {
      moduleType
      id
    }
    allSavedReports(first: 100000) {
      edges {
        node {
          id
          name
          type
          person {
            id
            fullName
            email
            profilePictureId
          }
          groupingFour
          reportService
          shares(first: 100) {
            edges {
              node {
                id
                user {
                  id
                  email
                }
                __typename
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          updatedAt
          privateAccess
          favorite
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "reports_page"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"reports_page\")"
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100000
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReportsPageRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportsPage_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ReportsPageRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "actualPersonId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeatureFlag",
                            "kind": "LinkedField",
                            "name": "availableFeatureFlags",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "key",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Module",
                                    "kind": "LinkedField",
                                    "name": "modules",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "moduleType",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "SavedReportTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "allSavedReports",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SavedReportTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SavedReportType",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "type",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Person",
                                                            "kind": "LinkedField",
                                                            "name": "person",
                                                            "plural": false,
                                                            "selections": [
                                                                (v1 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "fullName",
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "profilePictureId",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "groupingFour",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "reportService",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v4 /*: any*/),
                                                            "concreteType": "ReportShareTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "shares",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ReportShareTypeEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ReportShare",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v1 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ReportShareUser",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "user",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v1 /*: any*/),
                                                                                        (v3 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                },
                                                                                (v5 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": "shares(first:100)"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v4 /*: any*/),
                                                            "filters": [
                                                                ""
                                                            ],
                                                            "handle": "connection",
                                                            "key": "Report_shares",
                                                            "kind": "LinkedHandle",
                                                            "name": "shares"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "updatedAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "privateAccess",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "favorite",
                                                            "storageKey": null
                                                        },
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": "allSavedReports(first:100000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "Company_allSavedReports",
                                    "kind": "LinkedHandle",
                                    "name": "allSavedReports"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ReportsPageRefetchQuery",
            "operationKind": "query",
            "text": "query ReportsPageRefetchQuery {\n  viewer {\n    component(name: \"reports_page\")\n    ...ReportsPage_viewer\n    id\n  }\n}\n\nfragment ReportTable_company on Company {\n  id\n}\n\nfragment ReportsPage_viewer on Viewer {\n  id\n  actualPersonId\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    ...ReportTable_company\n    id\n    modules {\n      moduleType\n      id\n    }\n    allSavedReports(first: 100000) {\n      edges {\n        node {\n          id\n          name\n          type\n          person {\n            id\n            fullName\n            email\n            profilePictureId\n          }\n          groupingFour\n          reportService\n          shares(first: 100) {\n            edges {\n              node {\n                id\n                user {\n                  id\n                  email\n                }\n                __typename\n              }\n              cursor\n            }\n            pageInfo {\n              endCursor\n              hasNextPage\n            }\n          }\n          updatedAt\n          privateAccess\n          favorite\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '0432ca7e05404231904ba767ebdf1c1a';
export default node;
