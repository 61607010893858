import React, {useEffect, useState} from 'react';
import {
	DeprecatedCreativeModal as CreativeModal,
	FlexColumn,
	FlexItem,
	DeprecatedScrollbar as Scrollbar,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {SectionLine} from './JiraProjectSyncModal.styled';
import {JiraSection} from './sections/JiraProjectSyncJiraSection';
import {SettingsSection} from './sections/JiraProjectSyncSettingsSection';
import {TotalsSection} from './sections/JiraProjectSyncTotalsSection';
import {createFragmentContainer, graphql} from 'react-relay';
import {withSimpleSocketHandling} from '../../../../../../../socket/withSimpleSocketHandling';
import Util from '../../../../../util/util';
import Moment from 'moment';
import {
	getJiraBoards,
	getJiraCustomFields,
	getJiraProjectEpicsCount,
	getJiraProjectIssuesCount,
	getJiraSprintsCount,
	getJiraVersionsCount,
} from './JiraProjectSyncDataFetcher';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../../css_variables';
import {MODAL_TYPE, showModal} from '../../../generic_modal_conductor';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../../constants';
import SyncJiraIntegrationMutation from '../../../../../../../mutations/sync_jira_integration_mutation';
import InlineLoader from '../../../../inline-loader/inline_loader';
import {useHistory} from 'react-router-dom';
import {createToast} from '../../../../toasts/toast';
import {projectUrl} from '../../../../../../../directApi';

const JiraProjectSyncModal = ({viewer, forecastProject, jiraProject, closeModal}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const company = viewer?.company;
	const clientList = company
		? company.clients.edges.map(client => ({
				text: client.node.name,
				value: client.node.id,
		  }))
		: [];
	const unSyncedJiraProjects =
		company && company.unSynchedJiraProjects
			? company.unSynchedJiraProjects.map(jiraProject => ({
					text: jiraProject.name,
					value: jiraProject.id,
					style: jiraProject.style,
			  }))
			: [];

	const [synchronizing, setSynchronizing] = useState(false);

	//region Jira states
	const [selectedJiraBoard, setSelectedJiraBoard] = useState(undefined);
	const [jiraBoardOptions, setJiraBoardOptions] = useState([]);
	const [boardDeepFetched, setBoardsDeepFetched] = useState(false);
	const [customFieldOptions, setCustomFieldOptions] = useState([]);
	const [selectedCustomField, setSelectedCustomField] = useState(undefined);
	const [selectedJiraProjectStyle, setSelectedJiraProjectStyle] = useState(jiraProject?.style);
	//const [unSyncedEpicWorkLogs, setUnSyncedEpicWorkLogs] = useState([]);
	//endregion

	//region Jira section
	const [selectedJiraProject, setSelectedJiraProject] = useState(jiraProject?.id);
	const [forecastProjectName, setForecastProjectName] = useState(
		forecastProject ? forecastProject.name : jiraProject ? jiraProject.name : undefined
	);
	const [forecastProjectClient, setForecastProjectClient] = useState(
		forecastProject && forecastProject.client
			? clientList
				? clientList.find(client => client.value === forecastProject.client.id).value
				: undefined
			: undefined
	);
	const [startDate, setStartDate] = useState(
		forecastProject && forecastProject.projectStartDay
			? Util.CreateMomentDate(
					forecastProject.projectStartYear,
					forecastProject.projectStartMonth,
					forecastProject.projectStartDay
			  )
			: Moment()
	);
	const [endDate, setEndDate] = useState(
		forecastProject && forecastProject.projectEndDay
			? Util.CreateMomentDate(
					forecastProject.projectEndYear,
					forecastProject.projectEndMonth,
					forecastProject.projectEndDay
			  )
			: undefined
	);

	const handleJiraProjectSelected = selection => {
		const style = unSyncedJiraProjects.find(project => project.value === selection)?.style;
		setSelectedJiraProjectStyle(style);
		setSelectedJiraProject(selection);
	};

	const jiraSectionSetters = {
		handleJiraProjectSelected,
		setForecastProjectName,
		setForecastProjectClient,
		setStartDate,
		setEndDate,
	};

	const jiraSectionStates = {
		selectedJiraProject,
		forecastProjectName,
		forecastProjectClient,
		startDate,
		endDate,
	};
	//endregion

	//region Settings section
	const [syncJiraSprints, setSyncJiraSprints] = useState(false);
	const [syncVersionsToPhases, setSyncVersionsToPhases] = useState(true);
	const [syncEpicsToEpics, setSyncEpicsToEpics] = useState(false);
	const [useStoryPoints, setUseStoryPoints] = useState(false);
	const [syncRemainingTime, setSyncRemainingTime] = useState(false);
	const [syncJiraChildIssuesToToDos, setSyncJiraChildIssuesToToDos] = useState(false);

	const settingsSectionSetters = {
		setSyncJiraSprints,
		setSyncVersionsToPhases,
		setSyncEpicsToEpics,
		setUseStoryPoints,
		setSyncRemainingTime,
		setSyncJiraChildIssuesToToDos,
		setSelectedJiraBoard,
		setJiraBoardOptions,
		setBoardsDeepFetched,
		setSelectedCustomField,
	};

	const settingsSectionStates = {
		selectedJiraProject,
		syncJiraSprints,
		syncVersionsToPhases,
		syncEpicsToEpics,
		useStoryPoints,
		syncRemainingTime,
		syncJiraChildIssuesToToDos,
		selectedJiraBoard,
		jiraBoardOptions,
		selectedCustomField,
		customFieldOptions,
		boardDeepFetched,
	};
	//endregion

	//region Totals section
	const [syncTaskCount, setSyncTaskCount] = useState(0);
	const [syncSubtasksCount, setSyncSubtasksCount] = useState(0);
	const [syncEpicCount, setSyncEpicCount] = useState(0);
	const [syncVersionsCount, setSyncVersionsCount] = useState(0);
	const [syncSprintCount, setSyncSprintCount] = useState(0);

	const syncTotals = {
		syncTaskCount,
		syncSubtasksCount,
		syncEpicCount,
		syncVersionsCount,
		syncSprintCount,
	};

	const totalsStates = {
		selectedJiraProject,
		syncVersionsToPhases,
		syncEpicsToEpics,
		syncJiraChildIssuesToToDos,
		syncJiraSprints,
	};
	//endregion

	useEffect(() => {
		if (selectedJiraProject) {
			getJiraBoards(false, selectedJiraProject, {
				setSelectedJiraBoard,
				setJiraBoardOptions,
				setBoardsDeepFetched,
			});
			getJiraProjectIssuesCount(selectedJiraProject, {
				setSyncTaskCount,
				setSyncSubtasksCount,
			});
			getJiraVersionsCount(selectedJiraProject, {
				setSyncVersionsCount,
			});
			getJiraProjectEpicsCount(selectedJiraProject, {
				setSyncEpicCount,
			});
		}
	}, [selectedJiraProject]);

	useEffect(() => {
		if (selectedJiraProjectStyle) {
			getJiraCustomFields(selectedJiraProjectStyle, {
				setCustomFieldOptions,
				setSelectedCustomField,
			});
		}
	}, [selectedJiraProjectStyle]);

	useEffect(() => {
		if (selectedJiraBoard) {
			getJiraSprintsCount(selectedJiraBoard, {
				setSyncSprintCount,
			});
		}
	}, [selectedJiraBoard]);

	const showMissingInfoToast = () => {
		let toastMessage = '';
		if (!selectedJiraProject && !forecastProjectName) {
			toastMessage = formatMessage({id: 'sync_jira_project_modal.missing_info.jira_project_and_project_name'});
		} else if (!selectedJiraProject) {
			toastMessage = formatMessage({id: 'sync_jira_project_modal.missing_info.jira_project'});
		} else if (!forecastProjectName) {
			toastMessage = formatMessage({id: 'sync_jira_project_modal.missing_info.project_name'});
		}

		createToast({duration: 5000, message: toastMessage});
	};

	const syncProject = () => {
		const onSuccess = result => {
			const callbackPositive = () => {
				// go to new project
				const projectSection = syncJiraSprints ? 'sprints' : 'scoping';
				history.replace(
					`${projectUrl(
						result.syncJiraData.projectEdge.node.companyProjectId,
						result.syncJiraData.projectEdge.node.customProjectId
					)}/${projectSection}`
				);
			};
			const SyncedCompleteModalStyle = styled.div`
				.synced-complete-modal-title {
					font-size: 20px;
					font-weight: 500;
					color: ${CSS_CONSTANTS.v2_text_gray};
					margin-bottom: 25px;
					display: flex;
				}

				.synced-complete-modal-info {
					font-size: 13px;
					color: #535353;
					margin-bottom: 16px;

					&.bold {
						font-weight: 700;
					}
				}
			`;

			closeModal();

			if (!forecastProject) {
				const jiraProjectName = result.syncJiraData.projectEdge.node.jiraCloudProject?.name;

				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<SyncedCompleteModalStyle>
							<div>
								<div className="synced-complete-modal-title">
									{formatMessage({id: 'jira_app.synch_complete.title'})}
								</div>
								<div className="synced-complete-modal-info">
									{formatMessage({id: 'jira_app.synch_complete.info'}, {jiraProjectName})}
								</div>
							</div>
						</SyncedCompleteModalStyle>
					),
					className: 'default-warning-modal',
					buttons: [
						{
							text: formatMessage({id: 'jira_app.synch_complete.sync_more'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
						{
							text: formatMessage({id: 'common.go_to_project'}),
							callback: callbackPositive,
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					],
				});
			}
		};

		if (!selectedJiraProject || !forecastProjectName) {
			showMissingInfoToast();
			return;
		}

		const mutationObject = {
			jiraProjectId: selectedJiraProject,
			forecastProjectId: forecastProject ? forecastProject.id : undefined,
			boardId: syncJiraSprints ? selectedJiraBoard?.toString() : undefined,
			subtaskType: syncJiraChildIssuesToToDos ? 'subtasks' : 'tasks', //<-- Might have to be 'cards'
			epicType: syncVersionsToPhases ? (syncEpicsToEpics ? 'epic' : 'task') : 'milestone',
			type: 'cloud',
			estimationField: useStoryPoints ? selectedCustomField : 'timeoriginalestimate',
			name: forecastProjectName,
			projectColor: '#bc97f5',
			rateCardId: undefined,
			useManualAllocations: false,
			budgetType: BUDGET_TYPE.TIME_AND_MATERIALS,
			clientId: forecastProjectClient,
			budget: 0,
			jiraTypeToPhase: syncVersionsToPhases ? 'versions' : 'epics',
		};

		if (startDate) {
			mutationObject.projectStartDay = startDate.date();
			mutationObject.projectStartMonth = startDate.month() + 1;
			mutationObject.projectStartYear = startDate.year();
		}

		if (endDate) {
			mutationObject.projectEndDay = endDate.date();
			mutationObject.projectEndMonth = endDate.month() + 1;
			mutationObject.projectEndYear = endDate.year();
		}

		if (syncJiraSprints) {
			mutationObject.sprintTimeBox = !!selectedJiraBoard;
			mutationObject.sprintLengthInDays = 14;
		}

		mutationObject.remainingField = syncRemainingTime ? 'timeestimate' : null;

		Util.CommitMutation(SyncJiraIntegrationMutation, mutationObject, onSuccess);
		setSynchronizing(true);
	};

	return (
		<CreativeModal
			size={'l'}
			title={formatMessage({id: 'app_jira.sync_jira_project'})}
			closeModal={closeModal}
			onCreateHandler={() => syncProject()}
			creativeButtonText={'Sync project'}
		>
			{synchronizing ? (
				<InlineLoader />
			) : (
				<Scrollbar maxHeight={'70vh'}>
					<FlexColumn gap={'xxl'}>
						<FlexItem>
							<JiraSection
								forecastProject={forecastProject}
								jiraProject={jiraProject}
								jiraProjectList={unSyncedJiraProjects}
								forecastClientList={clientList}
								setters={jiraSectionSetters}
								states={jiraSectionStates}
							/>
						</FlexItem>
						<FlexItem>
							<SectionLine />
						</FlexItem>
						<FlexItem>
							<SettingsSection
								setters={settingsSectionSetters}
								states={settingsSectionStates}
								deepGetJiraBoards={getJiraBoards}
							/>
						</FlexItem>
						<FlexItem>
							<SectionLine />
						</FlexItem>
						<FlexItem>
							<TotalsSection states={totalsStates} syncingTotals={syncTotals} />
						</FlexItem>
					</FlexColumn>
				</Scrollbar>
			)}
		</CreativeModal>
	);
};

const JiraProjectSyncModalQuery = graphql`
	query JiraProjectSyncModal_Query($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "jira_project_sync_modal")
			...JiraProjectSyncModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {JiraProjectSyncModalQuery};

export default withSimpleSocketHandling(
	createFragmentContainer(JiraProjectSyncModal, {
		viewer: graphql`
			fragment JiraProjectSyncModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					tier
					currency
					modules {
						moduleType
					}
					defaultRateCard {
						id
					}
					unSynchedJiraProjects {
						id
						name
						style
					}
					unSynchedJiraServerProjects {
						id
						name
					}
					clients(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
					rateCards(first: 10000) {
						edges {
							node {
								id
								name
								currency
							}
						}
					}
					labels(first: 10000, labelType: PROJECT) {
						edges {
							node {
								id
								name
								color
								category {
									id
									name
								}
							}
						}
					}
				}
			}
		`,
	})
);
