import Util from '../../../../shared/util/util';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import TransferReportOwnership from '../../../../../mutations/reporting/TransferReportOwnershipMutation';
import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
export const transferOwnershipMutation = (report, newOwner, intl) => {
    const onSuccess = () => {
        createToast({
            duration: 5000,
            message: intl.formatMessage({ id: 'common.feedback.ownership_transferred' }, { entity: intl.formatMessage({ id: 'common.report' }).toLowerCase(), newOwner: newOwner.fullName }),
        });
    };
    Util.CommitMutation(TransferReportOwnership, {
        reportId: report.id,
        newOwnerId: newOwner.id,
    }, onSuccess);
};
export const transferOwnership = (report, intl) => {
    showModal({
        type: MODAL_TYPE.TRANSFER_REPORT_OWNERSHIP_MODAL,
        transferOwnership: (newOwner) => transferOwnershipMutation(report, newOwner, intl),
        report: report,
    });
};
