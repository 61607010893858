/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GenericSavedReportConductor_savedReport$ref = any;
export type RenameSavedReportInput = {|
  id: string,
  name?: ?string,
  reportService?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type renameSavedReportMutationVariables = {|
  input: RenameSavedReportInput
|};
export type renameSavedReportMutationResponse = {|
  +renameSavedReport: ?{|
    +savedReport: ?{|
      +id: string,
      +$fragmentRefs: GenericSavedReportConductor_savedReport$ref,
    |}
  |}
|};
export type renameSavedReportMutation = {|
  variables: renameSavedReportMutationVariables,
  response: renameSavedReportMutationResponse,
|};
*/


/*
mutation renameSavedReportMutation(
  $input: RenameSavedReportInput!
) {
  renameSavedReport(input: $input) {
    savedReport {
      id
      ...GenericSavedReportConductor_savedReport
    }
  }
}

fragment GenericSavedReportConductor_savedReport on SavedReportType {
  id
  updatedAt
  updatedBy {
    id
    fullName
  }
  type
  name
  person {
    id
    active
    email
    fullName
    profilePictureId
  }
  groupingOne
  groupingTwo
  groupingThree
  groupingFour
  eyeApplied
  filterApplied
  filters {
    field
    value
    operator
    id
  }
  startDate
  endDate
  periodType
  periodDate
  reportService
  shares(first: 100) {
    edges {
      node {
        id
        user {
          id
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  privateAccess
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RenameSavedReportInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    (v8/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingOne",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingTwo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingThree",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingFour",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eyeApplied",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterApplied",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "field",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operator",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportService",
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReportShareTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportShare",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportShareUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateAccess",
  "storageKey": null
},
v26 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "renameSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameSavedReportPayload",
        "kind": "LinkedField",
        "name": "renameSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineDataFragmentSpread",
                "name": "GenericSavedReportConductor_savedReport",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportFilterType",
                    "kind": "LinkedField",
                    "name": "filters",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": "shares",
                    "args": null,
                    "concreteType": "ReportShareTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Report_shares_connection",
                    "plural": false,
                    "selections": (v24/*: any*/),
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "renameSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameSavedReportPayload",
        "kind": "LinkedField",
        "name": "renameSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportFilterType",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": (v26/*: any*/),
                "concreteType": "ReportShareTypeConnection",
                "kind": "LinkedField",
                "name": "shares",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": "shares(first:100)"
              },
              {
                "alias": null,
                "args": (v26/*: any*/),
                "filters": [
                  ""
                ],
                "handle": "connection",
                "key": "Report_shares",
                "kind": "LinkedHandle",
                "name": "shares"
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "renameSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation renameSavedReportMutation(\n  $input: RenameSavedReportInput!\n) {\n  renameSavedReport(input: $input) {\n    savedReport {\n      id\n      ...GenericSavedReportConductor_savedReport\n    }\n  }\n}\n\nfragment GenericSavedReportConductor_savedReport on SavedReportType {\n  id\n  updatedAt\n  updatedBy {\n    id\n    fullName\n  }\n  type\n  name\n  person {\n    id\n    active\n    email\n    fullName\n    profilePictureId\n  }\n  groupingOne\n  groupingTwo\n  groupingThree\n  groupingFour\n  eyeApplied\n  filterApplied\n  filters {\n    field\n    value\n    operator\n    id\n  }\n  startDate\n  endDate\n  periodType\n  periodDate\n  reportService\n  shares(first: 100) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  privateAccess\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32e38a060babda70b95b10b10cfeba75';

module.exports = node;
